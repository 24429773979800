/* tslint:disable */
/* eslint-disable */
/**
 * Rapurc API-spec
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    EmailType,
    EmailTypeFromJSON,
    EmailTypeFromJSONTyped,
    EmailTypeToJSON,
} from './';

/**
 * Template of email with its type and necessary data
 * @export
 * @interface EmailTemplate
 */
export interface EmailTemplate {
    /**
     * target email address
     * @type {string}
     * @memberof EmailTemplate
     */
    emailAddress: string;
    /**
     * 
     * @type {EmailType}
     * @memberof EmailTemplate
     */
    emailType: EmailType;
    /**
     * Can be any of *Template (as long as it is consistent with emailType), e.g. BuildingDemolitionContactUpdateTemplate
     * @type {object}
     * @memberof EmailTemplate
     */
    emailData?: object;
}

export function EmailTemplateFromJSON(json: any): EmailTemplate {
    return EmailTemplateFromJSONTyped(json, false);
}

export function EmailTemplateFromJSONTyped(json: any, ignoreDiscriminator: boolean): EmailTemplate {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'emailAddress': json['emailAddress'],
        'emailType': EmailTypeFromJSON(json['emailType']),
        'emailData': !exists(json, 'emailData') ? undefined : json['emailData'],
    };
}

export function EmailTemplateToJSON(value?: EmailTemplate | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'emailAddress': value.emailAddress,
        'emailType': EmailTypeToJSON(value.emailType),
        'emailData': value.emailData,
    };
}


