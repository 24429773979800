/* tslint:disable */
/* eslint-disable */
/**
 * Rapurc API-spec
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    LocalizedValue,
    LocalizedValueFromJSON,
    LocalizedValueFromJSONTyped,
    LocalizedValueToJSON,
    Metadata,
    MetadataFromJSON,
    MetadataFromJSONTyped,
    MetadataToJSON,
} from './';

/**
 * Waste material object
 * @export
 * @interface WasteMaterial
 */
export interface WasteMaterial {
    /**
     * 
     * @type {string}
     * @memberof WasteMaterial
     */
    readonly id?: string;
    /**
     * Localized material names
     * @type {Array<LocalizedValue>}
     * @memberof WasteMaterial
     */
    localizedNames: Array<LocalizedValue>;
    /**
     * EWC (European Waste Code) category ID
     * @type {string}
     * @memberof WasteMaterial
     */
    wasteCategoryId: string;
    /**
     * Last two characters of EWC (European Waste Code) code
     * @type {string}
     * @memberof WasteMaterial
     */
    ewcSpecificationCode: string;
    /**
     * 
     * @type {Metadata}
     * @memberof WasteMaterial
     */
    metadata: Metadata;
}

export function WasteMaterialFromJSON(json: any): WasteMaterial {
    return WasteMaterialFromJSONTyped(json, false);
}

export function WasteMaterialFromJSONTyped(json: any, ignoreDiscriminator: boolean): WasteMaterial {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'localizedNames': ((json['localizedNames'] as Array<any>).map(LocalizedValueFromJSON)),
        'wasteCategoryId': json['wasteCategoryId'],
        'ewcSpecificationCode': json['ewcSpecificationCode'],
        'metadata': MetadataFromJSON(json['metadata']),
    };
}

export function WasteMaterialToJSON(value?: WasteMaterial | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'localizedNames': ((value.localizedNames as Array<any>).map(LocalizedValueToJSON)),
        'wasteCategoryId': value.wasteCategoryId,
        'ewcSpecificationCode': value.ewcSpecificationCode,
        'metadata': MetadataToJSON(value.metadata),
    };
}


