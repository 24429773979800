/* tslint:disable */
/* eslint-disable */
/**
 * Rapurc API-spec
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    WasteMaterial,
    WasteMaterialFromJSON,
    WasteMaterialToJSON,
} from '../models';

export interface CreateWasteMaterialRequest {
    wasteMaterial: WasteMaterial;
}

export interface DeleteWasteMaterialRequest {
    wasteMaterialId: string;
}

export interface FindWasteMaterialRequest {
    wasteMaterialId: string;
}

export interface UpdateWasteMaterialRequest {
    wasteMaterial: WasteMaterial;
    wasteMaterialId: string;
}

/**
 * 
 */
export class WasteMaterialApi extends runtime.BaseAPI {

    /**
     * Creates a new waste material.
     * Create a new waste material.
     */
    async createWasteMaterialRaw(requestParameters: CreateWasteMaterialRequest): Promise<runtime.ApiResponse<WasteMaterial>> {
        if (requestParameters.wasteMaterial === null || requestParameters.wasteMaterial === undefined) {
            throw new runtime.RequiredError('wasteMaterial','Required parameter requestParameters.wasteMaterial was null or undefined when calling createWasteMaterial.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/wasteMaterials`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: WasteMaterialToJSON(requestParameters.wasteMaterial),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => WasteMaterialFromJSON(jsonValue));
    }

    /**
     * Creates a new waste material.
     * Create a new waste material.
     */
    async createWasteMaterial(requestParameters: CreateWasteMaterialRequest): Promise<WasteMaterial> {
        const response = await this.createWasteMaterialRaw(requestParameters);
        return await response.value();
    }

    /**
     * Deletes waste material.
     * Delete waste material.
     */
    async deleteWasteMaterialRaw(requestParameters: DeleteWasteMaterialRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.wasteMaterialId === null || requestParameters.wasteMaterialId === undefined) {
            throw new runtime.RequiredError('wasteMaterialId','Required parameter requestParameters.wasteMaterialId was null or undefined when calling deleteWasteMaterial.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/wasteMaterials/{wasteMaterialId}`.replace(`{${"wasteMaterialId"}}`, encodeURIComponent(String(requestParameters.wasteMaterialId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Deletes waste material.
     * Delete waste material.
     */
    async deleteWasteMaterial(requestParameters: DeleteWasteMaterialRequest): Promise<void> {
        await this.deleteWasteMaterialRaw(requestParameters);
    }

    /**
     * Finds waste material by id.
     * Find waste material.
     */
    async findWasteMaterialRaw(requestParameters: FindWasteMaterialRequest): Promise<runtime.ApiResponse<WasteMaterial>> {
        if (requestParameters.wasteMaterialId === null || requestParameters.wasteMaterialId === undefined) {
            throw new runtime.RequiredError('wasteMaterialId','Required parameter requestParameters.wasteMaterialId was null or undefined when calling findWasteMaterial.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/wasteMaterials/{wasteMaterialId}`.replace(`{${"wasteMaterialId"}}`, encodeURIComponent(String(requestParameters.wasteMaterialId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => WasteMaterialFromJSON(jsonValue));
    }

    /**
     * Finds waste material by id.
     * Find waste material.
     */
    async findWasteMaterial(requestParameters: FindWasteMaterialRequest): Promise<WasteMaterial> {
        const response = await this.findWasteMaterialRaw(requestParameters);
        return await response.value();
    }

    /**
     * Lists waste material. Results are in alphabetical order
     * List waste materials
     */
    async listWasteMaterialsRaw(): Promise<runtime.ApiResponse<Array<WasteMaterial>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/wasteMaterials`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(WasteMaterialFromJSON));
    }

    /**
     * Lists waste material. Results are in alphabetical order
     * List waste materials
     */
    async listWasteMaterials(): Promise<Array<WasteMaterial>> {
        const response = await this.listWasteMaterialsRaw();
        return await response.value();
    }

    /**
     * Updates waste material object.
     * Update waste material object.
     */
    async updateWasteMaterialRaw(requestParameters: UpdateWasteMaterialRequest): Promise<runtime.ApiResponse<WasteMaterial>> {
        if (requestParameters.wasteMaterial === null || requestParameters.wasteMaterial === undefined) {
            throw new runtime.RequiredError('wasteMaterial','Required parameter requestParameters.wasteMaterial was null or undefined when calling updateWasteMaterial.');
        }

        if (requestParameters.wasteMaterialId === null || requestParameters.wasteMaterialId === undefined) {
            throw new runtime.RequiredError('wasteMaterialId','Required parameter requestParameters.wasteMaterialId was null or undefined when calling updateWasteMaterial.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/wasteMaterials/{wasteMaterialId}`.replace(`{${"wasteMaterialId"}}`, encodeURIComponent(String(requestParameters.wasteMaterialId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: WasteMaterialToJSON(requestParameters.wasteMaterial),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => WasteMaterialFromJSON(jsonValue));
    }

    /**
     * Updates waste material object.
     * Update waste material object.
     */
    async updateWasteMaterial(requestParameters: UpdateWasteMaterialRequest): Promise<WasteMaterial> {
        const response = await this.updateWasteMaterialRaw(requestParameters);
        return await response.value();
    }

}
