/* tslint:disable */
/* eslint-disable */
/**
 * Rapurc API-spec
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    Attachment,
    AttachmentFromJSON,
    AttachmentToJSON,
} from '../models';

export interface CreateSurveyAttachmentRequest {
    attachment: Attachment;
    surveyId: string;
}

export interface DeleteSurveyAttachmentRequest {
    surveyId: string;
    attachmentId: string;
}

export interface FindSurveyAttachmentRequest {
    surveyId: string;
    attachmentId: string;
}

export interface ListSurveyAttachmentsRequest {
    surveyId: string;
}

export interface UpdateSurveyAttachmentRequest {
    attachment: Attachment;
    surveyId: string;
    attachmentId: string;
}

/**
 * 
 */
export class SurveyAttachmentsApi extends runtime.BaseAPI {

    /**
     * Creates a new attachment object.
     * Create a new attachment object.
     */
    async createSurveyAttachmentRaw(requestParameters: CreateSurveyAttachmentRequest): Promise<runtime.ApiResponse<Attachment>> {
        if (requestParameters.attachment === null || requestParameters.attachment === undefined) {
            throw new runtime.RequiredError('attachment','Required parameter requestParameters.attachment was null or undefined when calling createSurveyAttachment.');
        }

        if (requestParameters.surveyId === null || requestParameters.surveyId === undefined) {
            throw new runtime.RequiredError('surveyId','Required parameter requestParameters.surveyId was null or undefined when calling createSurveyAttachment.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/surveys/{surveyId}/attachments`.replace(`{${"surveyId"}}`, encodeURIComponent(String(requestParameters.surveyId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AttachmentToJSON(requestParameters.attachment),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => AttachmentFromJSON(jsonValue));
    }

    /**
     * Creates a new attachment object.
     * Create a new attachment object.
     */
    async createSurveyAttachment(requestParameters: CreateSurveyAttachmentRequest): Promise<Attachment> {
        const response = await this.createSurveyAttachmentRaw(requestParameters);
        return await response.value();
    }

    /**
     * Deletes attachment from survey
     * Delete attachment from survey
     */
    async deleteSurveyAttachmentRaw(requestParameters: DeleteSurveyAttachmentRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.surveyId === null || requestParameters.surveyId === undefined) {
            throw new runtime.RequiredError('surveyId','Required parameter requestParameters.surveyId was null or undefined when calling deleteSurveyAttachment.');
        }

        if (requestParameters.attachmentId === null || requestParameters.attachmentId === undefined) {
            throw new runtime.RequiredError('attachmentId','Required parameter requestParameters.attachmentId was null or undefined when calling deleteSurveyAttachment.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/surveys/{surveyId}/attachments/{attachmentId}`.replace(`{${"surveyId"}}`, encodeURIComponent(String(requestParameters.surveyId))).replace(`{${"attachmentId"}}`, encodeURIComponent(String(requestParameters.attachmentId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Deletes attachment from survey
     * Delete attachment from survey
     */
    async deleteSurveyAttachment(requestParameters: DeleteSurveyAttachmentRequest): Promise<void> {
        await this.deleteSurveyAttachmentRaw(requestParameters);
    }

    /**
     * Finds attachment by id.
     * Find attachment.
     */
    async findSurveyAttachmentRaw(requestParameters: FindSurveyAttachmentRequest): Promise<runtime.ApiResponse<Attachment>> {
        if (requestParameters.surveyId === null || requestParameters.surveyId === undefined) {
            throw new runtime.RequiredError('surveyId','Required parameter requestParameters.surveyId was null or undefined when calling findSurveyAttachment.');
        }

        if (requestParameters.attachmentId === null || requestParameters.attachmentId === undefined) {
            throw new runtime.RequiredError('attachmentId','Required parameter requestParameters.attachmentId was null or undefined when calling findSurveyAttachment.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/surveys/{surveyId}/attachments/{attachmentId}`.replace(`{${"surveyId"}}`, encodeURIComponent(String(requestParameters.surveyId))).replace(`{${"attachmentId"}}`, encodeURIComponent(String(requestParameters.attachmentId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => AttachmentFromJSON(jsonValue));
    }

    /**
     * Finds attachment by id.
     * Find attachment.
     */
    async findSurveyAttachment(requestParameters: FindSurveyAttachmentRequest): Promise<Attachment> {
        const response = await this.findSurveyAttachmentRaw(requestParameters);
        return await response.value();
    }

    /**
     * Lists attachments from survey
     * List attachments from survey
     */
    async listSurveyAttachmentsRaw(requestParameters: ListSurveyAttachmentsRequest): Promise<runtime.ApiResponse<Array<Attachment>>> {
        if (requestParameters.surveyId === null || requestParameters.surveyId === undefined) {
            throw new runtime.RequiredError('surveyId','Required parameter requestParameters.surveyId was null or undefined when calling listSurveyAttachments.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/surveys/{surveyId}/attachments`.replace(`{${"surveyId"}}`, encodeURIComponent(String(requestParameters.surveyId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(AttachmentFromJSON));
    }

    /**
     * Lists attachments from survey
     * List attachments from survey
     */
    async listSurveyAttachments(requestParameters: ListSurveyAttachmentsRequest): Promise<Array<Attachment>> {
        const response = await this.listSurveyAttachmentsRaw(requestParameters);
        return await response.value();
    }

    /**
     * Updates attachment object.
     * Update attachment object.
     */
    async updateSurveyAttachmentRaw(requestParameters: UpdateSurveyAttachmentRequest): Promise<runtime.ApiResponse<Attachment>> {
        if (requestParameters.attachment === null || requestParameters.attachment === undefined) {
            throw new runtime.RequiredError('attachment','Required parameter requestParameters.attachment was null or undefined when calling updateSurveyAttachment.');
        }

        if (requestParameters.surveyId === null || requestParameters.surveyId === undefined) {
            throw new runtime.RequiredError('surveyId','Required parameter requestParameters.surveyId was null or undefined when calling updateSurveyAttachment.');
        }

        if (requestParameters.attachmentId === null || requestParameters.attachmentId === undefined) {
            throw new runtime.RequiredError('attachmentId','Required parameter requestParameters.attachmentId was null or undefined when calling updateSurveyAttachment.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/surveys/{surveyId}/attachments/{attachmentId}`.replace(`{${"surveyId"}}`, encodeURIComponent(String(requestParameters.surveyId))).replace(`{${"attachmentId"}}`, encodeURIComponent(String(requestParameters.attachmentId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: AttachmentToJSON(requestParameters.attachment),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => AttachmentFromJSON(jsonValue));
    }

    /**
     * Updates attachment object.
     * Update attachment object.
     */
    async updateSurveyAttachment(requestParameters: UpdateSurveyAttachmentRequest): Promise<Attachment> {
        const response = await this.updateSurveyAttachmentRaw(requestParameters);
        return await response.value();
    }

}
