/* tslint:disable */
/* eslint-disable */
/**
 * Rapurc API-spec
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Metadata,
    MetadataFromJSON,
    MetadataFromJSONTyped,
    MetadataToJSON,
} from './';

/**
 * Surveyor object
 * @export
 * @interface Surveyor
 */
export interface Surveyor {
    /**
     * 
     * @type {string}
     * @memberof Surveyor
     */
    readonly id?: string;
    /**
     * Id of the survey
     * @type {string}
     * @memberof Surveyor
     */
    surveyId?: string;
    /**
     * Surveyor first name
     * @type {string}
     * @memberof Surveyor
     */
    firstName: string;
    /**
     * Surveyor last name
     * @type {string}
     * @memberof Surveyor
     */
    lastName: string;
    /**
     * Surveyors company
     * @type {string}
     * @memberof Surveyor
     */
    company: string;
    /**
     * Surveyors role
     * @type {string}
     * @memberof Surveyor
     */
    role?: string;
    /**
     * Surveyors phone number
     * @type {string}
     * @memberof Surveyor
     */
    phone: string;
    /**
     * Surveyors phone email
     * @type {string}
     * @memberof Surveyor
     */
    email?: string;
    /**
     * Report date
     * @type {Date}
     * @memberof Surveyor
     */
    reportDate?: Date;
    /**
     * Surveying visits
     * @type {string}
     * @memberof Surveyor
     */
    visits?: string;
    /**
     * 
     * @type {Metadata}
     * @memberof Surveyor
     */
    metadata?: Metadata;
}

export function SurveyorFromJSON(json: any): Surveyor {
    return SurveyorFromJSONTyped(json, false);
}

export function SurveyorFromJSONTyped(json: any, ignoreDiscriminator: boolean): Surveyor {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'surveyId': !exists(json, 'surveyId') ? undefined : json['surveyId'],
        'firstName': json['firstName'],
        'lastName': json['lastName'],
        'company': json['company'],
        'role': !exists(json, 'role') ? undefined : json['role'],
        'phone': json['phone'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'reportDate': !exists(json, 'reportDate') ? undefined : (new Date(json['reportDate'])),
        'visits': !exists(json, 'visits') ? undefined : json['visits'],
        'metadata': !exists(json, 'metadata') ? undefined : MetadataFromJSON(json['metadata']),
    };
}

export function SurveyorToJSON(value?: Surveyor | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'surveyId': value.surveyId,
        'firstName': value.firstName,
        'lastName': value.lastName,
        'company': value.company,
        'role': value.role,
        'phone': value.phone,
        'email': value.email,
        'reportDate': value.reportDate === undefined ? undefined : (value.reportDate.toISOString().substr(0,10)),
        'visits': value.visits,
        'metadata': MetadataToJSON(value.metadata),
    };
}


