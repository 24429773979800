/* tslint:disable */
/* eslint-disable */
/**
 * Rapurc API-spec
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    JoinRequestStatus,
    JoinRequestStatusFromJSON,
    JoinRequestStatusFromJSONTyped,
    JoinRequestStatusToJSON,
    Metadata,
    MetadataFromJSON,
    MetadataFromJSONTyped,
    MetadataToJSON,
} from './';

/**
 * Request of a user to join a group
 * @export
 * @interface GroupJoinRequest
 */
export interface GroupJoinRequest {
    /**
     * 
     * @type {string}
     * @memberof GroupJoinRequest
     */
    readonly id?: string;
    /**
     * user email address
     * @type {string}
     * @memberof GroupJoinRequest
     */
    email: string;
    /**
     * user first name
     * @type {string}
     * @memberof GroupJoinRequest
     */
    firstName?: string;
    /**
     * user last name
     * @type {string}
     * @memberof GroupJoinRequest
     */
    lastName?: string;
    /**
     * user username
     * @type {string}
     * @memberof GroupJoinRequest
     */
    username?: string;
    /**
     * group id
     * @type {string}
     * @memberof GroupJoinRequest
     */
    groupId: string;
    /**
     * 
     * @type {JoinRequestStatus}
     * @memberof GroupJoinRequest
     */
    status?: JoinRequestStatus;
    /**
     * 
     * @type {Metadata}
     * @memberof GroupJoinRequest
     */
    metadata?: Metadata;
}

export function GroupJoinRequestFromJSON(json: any): GroupJoinRequest {
    return GroupJoinRequestFromJSONTyped(json, false);
}

export function GroupJoinRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): GroupJoinRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'email': json['email'],
        'firstName': !exists(json, 'firstName') ? undefined : json['firstName'],
        'lastName': !exists(json, 'lastName') ? undefined : json['lastName'],
        'username': !exists(json, 'username') ? undefined : json['username'],
        'groupId': json['groupId'],
        'status': !exists(json, 'status') ? undefined : JoinRequestStatusFromJSON(json['status']),
        'metadata': !exists(json, 'metadata') ? undefined : MetadataFromJSON(json['metadata']),
    };
}

export function GroupJoinRequestToJSON(value?: GroupJoinRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'email': value.email,
        'firstName': value.firstName,
        'lastName': value.lastName,
        'username': value.username,
        'groupId': value.groupId,
        'status': JoinRequestStatusToJSON(value.status),
        'metadata': MetadataToJSON(value.metadata),
    };
}


