/* tslint:disable */
/* eslint-disable */
/**
 * Rapurc API-spec
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    Reusable,
    ReusableFromJSON,
    ReusableToJSON,
} from '../models';

export interface CreateSurveyReusableRequest {
    reusable: Reusable;
    surveyId: string;
}

export interface DeleteSurveyReusableRequest {
    surveyId: string;
    reusableId: string;
}

export interface FindSurveyReusableRequest {
    surveyId: string;
    reusableId: string;
}

export interface ListSurveyReusablesRequest {
    surveyId: string;
}

export interface UpdateSurveyReusableRequest {
    reusable: Reusable;
    surveyId: string;
    reusableId: string;
}

/**
 * 
 */
export class SurveyReusablesApi extends runtime.BaseAPI {

    /**
     * Creates a new reusable construction material object.
     * Create a new reusable construction material object.
     */
    async createSurveyReusableRaw(requestParameters: CreateSurveyReusableRequest): Promise<runtime.ApiResponse<Reusable>> {
        if (requestParameters.reusable === null || requestParameters.reusable === undefined) {
            throw new runtime.RequiredError('reusable','Required parameter requestParameters.reusable was null or undefined when calling createSurveyReusable.');
        }

        if (requestParameters.surveyId === null || requestParameters.surveyId === undefined) {
            throw new runtime.RequiredError('surveyId','Required parameter requestParameters.surveyId was null or undefined when calling createSurveyReusable.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/surveys/{surveyId}/reusables`.replace(`{${"surveyId"}}`, encodeURIComponent(String(requestParameters.surveyId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ReusableToJSON(requestParameters.reusable),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ReusableFromJSON(jsonValue));
    }

    /**
     * Creates a new reusable construction material object.
     * Create a new reusable construction material object.
     */
    async createSurveyReusable(requestParameters: CreateSurveyReusableRequest): Promise<Reusable> {
        const response = await this.createSurveyReusableRaw(requestParameters);
        return await response.value();
    }

    /**
     * Deletes reusable construction material from survey
     * Delete reusable construction material from survey
     */
    async deleteSurveyReusableRaw(requestParameters: DeleteSurveyReusableRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.surveyId === null || requestParameters.surveyId === undefined) {
            throw new runtime.RequiredError('surveyId','Required parameter requestParameters.surveyId was null or undefined when calling deleteSurveyReusable.');
        }

        if (requestParameters.reusableId === null || requestParameters.reusableId === undefined) {
            throw new runtime.RequiredError('reusableId','Required parameter requestParameters.reusableId was null or undefined when calling deleteSurveyReusable.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/surveys/{surveyId}/reusables/{reusableId}`.replace(`{${"surveyId"}}`, encodeURIComponent(String(requestParameters.surveyId))).replace(`{${"reusableId"}}`, encodeURIComponent(String(requestParameters.reusableId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Deletes reusable construction material from survey
     * Delete reusable construction material from survey
     */
    async deleteSurveyReusable(requestParameters: DeleteSurveyReusableRequest): Promise<void> {
        await this.deleteSurveyReusableRaw(requestParameters);
    }

    /**
     * Finds reusable construction material by id.
     * Find reusable.
     */
    async findSurveyReusableRaw(requestParameters: FindSurveyReusableRequest): Promise<runtime.ApiResponse<Reusable>> {
        if (requestParameters.surveyId === null || requestParameters.surveyId === undefined) {
            throw new runtime.RequiredError('surveyId','Required parameter requestParameters.surveyId was null or undefined when calling findSurveyReusable.');
        }

        if (requestParameters.reusableId === null || requestParameters.reusableId === undefined) {
            throw new runtime.RequiredError('reusableId','Required parameter requestParameters.reusableId was null or undefined when calling findSurveyReusable.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/surveys/{surveyId}/reusables/{reusableId}`.replace(`{${"surveyId"}}`, encodeURIComponent(String(requestParameters.surveyId))).replace(`{${"reusableId"}}`, encodeURIComponent(String(requestParameters.reusableId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ReusableFromJSON(jsonValue));
    }

    /**
     * Finds reusable construction material by id.
     * Find reusable.
     */
    async findSurveyReusable(requestParameters: FindSurveyReusableRequest): Promise<Reusable> {
        const response = await this.findSurveyReusableRaw(requestParameters);
        return await response.value();
    }

    /**
     * Lists reusable construction material from survey
     * List reusable construction material from survey
     */
    async listSurveyReusablesRaw(requestParameters: ListSurveyReusablesRequest): Promise<runtime.ApiResponse<Array<Reusable>>> {
        if (requestParameters.surveyId === null || requestParameters.surveyId === undefined) {
            throw new runtime.RequiredError('surveyId','Required parameter requestParameters.surveyId was null or undefined when calling listSurveyReusables.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/surveys/{surveyId}/reusables`.replace(`{${"surveyId"}}`, encodeURIComponent(String(requestParameters.surveyId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ReusableFromJSON));
    }

    /**
     * Lists reusable construction material from survey
     * List reusable construction material from survey
     */
    async listSurveyReusables(requestParameters: ListSurveyReusablesRequest): Promise<Array<Reusable>> {
        const response = await this.listSurveyReusablesRaw(requestParameters);
        return await response.value();
    }

    /**
     * Updates reusable construction material object.
     * Update reusable construction material object.
     */
    async updateSurveyReusableRaw(requestParameters: UpdateSurveyReusableRequest): Promise<runtime.ApiResponse<Reusable>> {
        if (requestParameters.reusable === null || requestParameters.reusable === undefined) {
            throw new runtime.RequiredError('reusable','Required parameter requestParameters.reusable was null or undefined when calling updateSurveyReusable.');
        }

        if (requestParameters.surveyId === null || requestParameters.surveyId === undefined) {
            throw new runtime.RequiredError('surveyId','Required parameter requestParameters.surveyId was null or undefined when calling updateSurveyReusable.');
        }

        if (requestParameters.reusableId === null || requestParameters.reusableId === undefined) {
            throw new runtime.RequiredError('reusableId','Required parameter requestParameters.reusableId was null or undefined when calling updateSurveyReusable.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/surveys/{surveyId}/reusables/{reusableId}`.replace(`{${"surveyId"}}`, encodeURIComponent(String(requestParameters.surveyId))).replace(`{${"reusableId"}}`, encodeURIComponent(String(requestParameters.reusableId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ReusableToJSON(requestParameters.reusable),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ReusableFromJSON(jsonValue));
    }

    /**
     * Updates reusable construction material object.
     * Update reusable construction material object.
     */
    async updateSurveyReusable(requestParameters: UpdateSurveyReusableRequest): Promise<Reusable> {
        const response = await this.updateSurveyReusableRaw(requestParameters);
        return await response.value();
    }

}
