/* tslint:disable */
/* eslint-disable */
/**
 * Rapurc API-spec
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ContactPerson,
    ContactPersonFromJSON,
    ContactPersonFromJSONTyped,
    ContactPersonToJSON,
    Metadata,
    MetadataFromJSON,
    MetadataFromJSONTyped,
    MetadataToJSON,
} from './';

/**
 * Information about the owner of the building
 * @export
 * @interface OwnerInformation
 */
export interface OwnerInformation {
    /**
     * Id of the building information object
     * @type {string}
     * @memberof OwnerInformation
     */
    readonly id?: string;
    /**
     * Id of the survey
     * @type {string}
     * @memberof OwnerInformation
     */
    surveyId: string;
    /**
     * Name of the owner
     * @type {string}
     * @memberof OwnerInformation
     */
    ownerName?: string;
    /**
     * Business ID
     * @type {string}
     * @memberof OwnerInformation
     */
    businessId?: string;
    /**
     * 
     * @type {ContactPerson}
     * @memberof OwnerInformation
     */
    contactPerson?: ContactPerson;
    /**
     * 
     * @type {Metadata}
     * @memberof OwnerInformation
     */
    metadata: Metadata;
}

export function OwnerInformationFromJSON(json: any): OwnerInformation {
    return OwnerInformationFromJSONTyped(json, false);
}

export function OwnerInformationFromJSONTyped(json: any, ignoreDiscriminator: boolean): OwnerInformation {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'surveyId': json['surveyId'],
        'ownerName': !exists(json, 'ownerName') ? undefined : json['ownerName'],
        'businessId': !exists(json, 'businessId') ? undefined : json['businessId'],
        'contactPerson': !exists(json, 'contactPerson') ? undefined : ContactPersonFromJSON(json['contactPerson']),
        'metadata': MetadataFromJSON(json['metadata']),
    };
}

export function OwnerInformationToJSON(value?: OwnerInformation | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'surveyId': value.surveyId,
        'ownerName': value.ownerName,
        'businessId': value.businessId,
        'contactPerson': ContactPersonToJSON(value.contactPerson),
        'metadata': MetadataToJSON(value.metadata),
    };
}


