/* tslint:disable */
/* eslint-disable */
/**
 * Rapurc API-spec
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    Surveyor,
    SurveyorFromJSON,
    SurveyorToJSON,
} from '../models';

export interface CreateSurveyorRequest {
    surveyor: Surveyor;
    surveyId: string;
}

export interface DeleteSurveyorRequest {
    surveyId: string;
    surveyorId: string;
}

export interface FindSurveyorRequest {
    surveyId: string;
    surveyorId: string;
}

export interface ListSurveyorsRequest {
    surveyId: string;
}

export interface UpdateSurveyorRequest {
    surveyor: Surveyor;
    surveyId: string;
    surveyorId: string;
}

/**
 * 
 */
export class SurveyorsApi extends runtime.BaseAPI {

    /**
     * Creates a new surveyor object.
     * Create a new surveyor object.
     */
    async createSurveyorRaw(requestParameters: CreateSurveyorRequest): Promise<runtime.ApiResponse<Surveyor>> {
        if (requestParameters.surveyor === null || requestParameters.surveyor === undefined) {
            throw new runtime.RequiredError('surveyor','Required parameter requestParameters.surveyor was null or undefined when calling createSurveyor.');
        }

        if (requestParameters.surveyId === null || requestParameters.surveyId === undefined) {
            throw new runtime.RequiredError('surveyId','Required parameter requestParameters.surveyId was null or undefined when calling createSurveyor.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/surveys/{surveyId}/surveyors`.replace(`{${"surveyId"}}`, encodeURIComponent(String(requestParameters.surveyId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SurveyorToJSON(requestParameters.surveyor),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => SurveyorFromJSON(jsonValue));
    }

    /**
     * Creates a new surveyor object.
     * Create a new surveyor object.
     */
    async createSurveyor(requestParameters: CreateSurveyorRequest): Promise<Surveyor> {
        const response = await this.createSurveyorRaw(requestParameters);
        return await response.value();
    }

    /**
     * Deletes surveyor.
     * Delete surveyor.
     */
    async deleteSurveyorRaw(requestParameters: DeleteSurveyorRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.surveyId === null || requestParameters.surveyId === undefined) {
            throw new runtime.RequiredError('surveyId','Required parameter requestParameters.surveyId was null or undefined when calling deleteSurveyor.');
        }

        if (requestParameters.surveyorId === null || requestParameters.surveyorId === undefined) {
            throw new runtime.RequiredError('surveyorId','Required parameter requestParameters.surveyorId was null or undefined when calling deleteSurveyor.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/surveys/{surveyId}/surveyors/{surveyorId}`.replace(`{${"surveyId"}}`, encodeURIComponent(String(requestParameters.surveyId))).replace(`{${"surveyorId"}}`, encodeURIComponent(String(requestParameters.surveyorId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Deletes surveyor.
     * Delete surveyor.
     */
    async deleteSurveyor(requestParameters: DeleteSurveyorRequest): Promise<void> {
        await this.deleteSurveyorRaw(requestParameters);
    }

    /**
     * Finds surveyor by id.
     * Find surveyor.
     */
    async findSurveyorRaw(requestParameters: FindSurveyorRequest): Promise<runtime.ApiResponse<Surveyor>> {
        if (requestParameters.surveyId === null || requestParameters.surveyId === undefined) {
            throw new runtime.RequiredError('surveyId','Required parameter requestParameters.surveyId was null or undefined when calling findSurveyor.');
        }

        if (requestParameters.surveyorId === null || requestParameters.surveyorId === undefined) {
            throw new runtime.RequiredError('surveyorId','Required parameter requestParameters.surveyorId was null or undefined when calling findSurveyor.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/surveys/{surveyId}/surveyors/{surveyorId}`.replace(`{${"surveyId"}}`, encodeURIComponent(String(requestParameters.surveyId))).replace(`{${"surveyorId"}}`, encodeURIComponent(String(requestParameters.surveyorId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => SurveyorFromJSON(jsonValue));
    }

    /**
     * Finds surveyor by id.
     * Find surveyor.
     */
    async findSurveyor(requestParameters: FindSurveyorRequest): Promise<Surveyor> {
        const response = await this.findSurveyorRaw(requestParameters);
        return await response.value();
    }

    /**
     * Lists surveyors
     * List surveyors
     */
    async listSurveyorsRaw(requestParameters: ListSurveyorsRequest): Promise<runtime.ApiResponse<Array<Surveyor>>> {
        if (requestParameters.surveyId === null || requestParameters.surveyId === undefined) {
            throw new runtime.RequiredError('surveyId','Required parameter requestParameters.surveyId was null or undefined when calling listSurveyors.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/surveys/{surveyId}/surveyors`.replace(`{${"surveyId"}}`, encodeURIComponent(String(requestParameters.surveyId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(SurveyorFromJSON));
    }

    /**
     * Lists surveyors
     * List surveyors
     */
    async listSurveyors(requestParameters: ListSurveyorsRequest): Promise<Array<Surveyor>> {
        const response = await this.listSurveyorsRaw(requestParameters);
        return await response.value();
    }

    /**
     * Updates surveyor object.
     * Update surveyor object.
     */
    async updateSurveyorRaw(requestParameters: UpdateSurveyorRequest): Promise<runtime.ApiResponse<Surveyor>> {
        if (requestParameters.surveyor === null || requestParameters.surveyor === undefined) {
            throw new runtime.RequiredError('surveyor','Required parameter requestParameters.surveyor was null or undefined when calling updateSurveyor.');
        }

        if (requestParameters.surveyId === null || requestParameters.surveyId === undefined) {
            throw new runtime.RequiredError('surveyId','Required parameter requestParameters.surveyId was null or undefined when calling updateSurveyor.');
        }

        if (requestParameters.surveyorId === null || requestParameters.surveyorId === undefined) {
            throw new runtime.RequiredError('surveyorId','Required parameter requestParameters.surveyorId was null or undefined when calling updateSurveyor.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/surveys/{surveyId}/surveyors/{surveyorId}`.replace(`{${"surveyId"}}`, encodeURIComponent(String(requestParameters.surveyId))).replace(`{${"surveyorId"}}`, encodeURIComponent(String(requestParameters.surveyorId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: SurveyorToJSON(requestParameters.surveyor),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => SurveyorFromJSON(jsonValue));
    }

    /**
     * Updates surveyor object.
     * Update surveyor object.
     */
    async updateSurveyor(requestParameters: UpdateSurveyorRequest): Promise<Surveyor> {
        const response = await this.updateSurveyorRaw(requestParameters);
        return await response.value();
    }

}
