/* tslint:disable */
/* eslint-disable */
/**
 * Rapurc API-spec
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    BuildingType,
    BuildingTypeFromJSON,
    BuildingTypeToJSON,
} from '../models';

export interface CreateBuildingTypeRequest {
    buildingType: BuildingType;
}

export interface DeleteBuildingTypeRequest {
    buildingTypeId: string;
}

export interface FindBuildingTypeRequest {
    buildingTypeId: string;
}

export interface UpdateBuildingTypeRequest {
    buildingType: BuildingType;
    buildingTypeId: string;
}

/**
 * 
 */
export class BuildingTypesApi extends runtime.BaseAPI {

    /**
     * Creates a new building type.
     * Create a building type.
     */
    async createBuildingTypeRaw(requestParameters: CreateBuildingTypeRequest): Promise<runtime.ApiResponse<BuildingType>> {
        if (requestParameters.buildingType === null || requestParameters.buildingType === undefined) {
            throw new runtime.RequiredError('buildingType','Required parameter requestParameters.buildingType was null or undefined when calling createBuildingType.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/buildingTypes`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: BuildingTypeToJSON(requestParameters.buildingType),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => BuildingTypeFromJSON(jsonValue));
    }

    /**
     * Creates a new building type.
     * Create a building type.
     */
    async createBuildingType(requestParameters: CreateBuildingTypeRequest): Promise<BuildingType> {
        const response = await this.createBuildingTypeRaw(requestParameters);
        return await response.value();
    }

    /**
     * Deletes a building type.
     * Delete a building type.
     */
    async deleteBuildingTypeRaw(requestParameters: DeleteBuildingTypeRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.buildingTypeId === null || requestParameters.buildingTypeId === undefined) {
            throw new runtime.RequiredError('buildingTypeId','Required parameter requestParameters.buildingTypeId was null or undefined when calling deleteBuildingType.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/buildingTypes/{buildingTypeId}`.replace(`{${"buildingTypeId"}}`, encodeURIComponent(String(requestParameters.buildingTypeId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Deletes a building type.
     * Delete a building type.
     */
    async deleteBuildingType(requestParameters: DeleteBuildingTypeRequest): Promise<void> {
        await this.deleteBuildingTypeRaw(requestParameters);
    }

    /**
     * Finds a building type by id.
     * Find a building type.
     */
    async findBuildingTypeRaw(requestParameters: FindBuildingTypeRequest): Promise<runtime.ApiResponse<BuildingType>> {
        if (requestParameters.buildingTypeId === null || requestParameters.buildingTypeId === undefined) {
            throw new runtime.RequiredError('buildingTypeId','Required parameter requestParameters.buildingTypeId was null or undefined when calling findBuildingType.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/buildingTypes/{buildingTypeId}`.replace(`{${"buildingTypeId"}}`, encodeURIComponent(String(requestParameters.buildingTypeId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => BuildingTypeFromJSON(jsonValue));
    }

    /**
     * Finds a building type by id.
     * Find a building type.
     */
    async findBuildingType(requestParameters: FindBuildingTypeRequest): Promise<BuildingType> {
        const response = await this.findBuildingTypeRaw(requestParameters);
        return await response.value();
    }

    /**
     * Lists building types.
     * List building types.
     */
    async listBuildingTypesRaw(): Promise<runtime.ApiResponse<Array<BuildingType>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/buildingTypes`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(BuildingTypeFromJSON));
    }

    /**
     * Lists building types.
     * List building types.
     */
    async listBuildingTypes(): Promise<Array<BuildingType>> {
        const response = await this.listBuildingTypesRaw();
        return await response.value();
    }

    /**
     * Updates a building type
     * Update a building type.
     */
    async updateBuildingTypeRaw(requestParameters: UpdateBuildingTypeRequest): Promise<runtime.ApiResponse<BuildingType>> {
        if (requestParameters.buildingType === null || requestParameters.buildingType === undefined) {
            throw new runtime.RequiredError('buildingType','Required parameter requestParameters.buildingType was null or undefined when calling updateBuildingType.');
        }

        if (requestParameters.buildingTypeId === null || requestParameters.buildingTypeId === undefined) {
            throw new runtime.RequiredError('buildingTypeId','Required parameter requestParameters.buildingTypeId was null or undefined when calling updateBuildingType.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/buildingTypes/{buildingTypeId}`.replace(`{${"buildingTypeId"}}`, encodeURIComponent(String(requestParameters.buildingTypeId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: BuildingTypeToJSON(requestParameters.buildingType),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => BuildingTypeFromJSON(jsonValue));
    }

    /**
     * Updates a building type
     * Update a building type.
     */
    async updateBuildingType(requestParameters: UpdateBuildingTypeRequest): Promise<BuildingType> {
        const response = await this.updateBuildingTypeRaw(requestParameters);
        return await response.value();
    }

}
