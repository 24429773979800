/* tslint:disable */
/* eslint-disable */
/**
 * Rapurc API-spec
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Survey status enum
 * @export
 * @enum {string}
 */
export enum SurveyStatus {
    Draft = 'DRAFT',
    Done = 'DONE'
}

export function SurveyStatusFromJSON(json: any): SurveyStatus {
    return SurveyStatusFromJSONTyped(json, false);
}

export function SurveyStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): SurveyStatus {
    return json as SurveyStatus;
}

export function SurveyStatusToJSON(value?: SurveyStatus | null): any {
    return value as any;
}

