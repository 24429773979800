/* tslint:disable */
/* eslint-disable */
/**
 * Rapurc API-spec
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    HazardousWaste,
    HazardousWasteFromJSON,
    HazardousWasteToJSON,
} from '../models';

export interface CreateSurveyHazardousWasteRequest {
    hazardousWaste: HazardousWaste;
    surveyId: string;
}

export interface DeleteSurveyHazardousWasteRequest {
    surveyId: string;
    hazardousWasteId: string;
}

export interface FindSurveyHazardousWasteRequest {
    surveyId: string;
    hazardousWasteId: string;
}

export interface ListSurveyHazardousWastesRequest {
    surveyId: string;
}

export interface UpdateSurveyHazardousWasteRequest {
    hazardousWaste: HazardousWaste;
    surveyId: string;
    hazardousWasteId: string;
}

/**
 * 
 */
export class SurveyHazardousWastesApi extends runtime.BaseAPI {

    /**
     * Creates a new hazardous waste construction material object.
     * Create a new hazardous waste construction material object.
     */
    async createSurveyHazardousWasteRaw(requestParameters: CreateSurveyHazardousWasteRequest): Promise<runtime.ApiResponse<HazardousWaste>> {
        if (requestParameters.hazardousWaste === null || requestParameters.hazardousWaste === undefined) {
            throw new runtime.RequiredError('hazardousWaste','Required parameter requestParameters.hazardousWaste was null or undefined when calling createSurveyHazardousWaste.');
        }

        if (requestParameters.surveyId === null || requestParameters.surveyId === undefined) {
            throw new runtime.RequiredError('surveyId','Required parameter requestParameters.surveyId was null or undefined when calling createSurveyHazardousWaste.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/surveys/{surveyId}/hazardousWastes`.replace(`{${"surveyId"}}`, encodeURIComponent(String(requestParameters.surveyId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: HazardousWasteToJSON(requestParameters.hazardousWaste),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => HazardousWasteFromJSON(jsonValue));
    }

    /**
     * Creates a new hazardous waste construction material object.
     * Create a new hazardous waste construction material object.
     */
    async createSurveyHazardousWaste(requestParameters: CreateSurveyHazardousWasteRequest): Promise<HazardousWaste> {
        const response = await this.createSurveyHazardousWasteRaw(requestParameters);
        return await response.value();
    }

    /**
     * Deletes hazardous waste construction material from survey
     * Delete hazardous waste construction material from survey
     */
    async deleteSurveyHazardousWasteRaw(requestParameters: DeleteSurveyHazardousWasteRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.surveyId === null || requestParameters.surveyId === undefined) {
            throw new runtime.RequiredError('surveyId','Required parameter requestParameters.surveyId was null or undefined when calling deleteSurveyHazardousWaste.');
        }

        if (requestParameters.hazardousWasteId === null || requestParameters.hazardousWasteId === undefined) {
            throw new runtime.RequiredError('hazardousWasteId','Required parameter requestParameters.hazardousWasteId was null or undefined when calling deleteSurveyHazardousWaste.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/surveys/{surveyId}/hazardousWastes/{hazardousWasteId}`.replace(`{${"surveyId"}}`, encodeURIComponent(String(requestParameters.surveyId))).replace(`{${"hazardousWasteId"}}`, encodeURIComponent(String(requestParameters.hazardousWasteId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Deletes hazardous waste construction material from survey
     * Delete hazardous waste construction material from survey
     */
    async deleteSurveyHazardousWaste(requestParameters: DeleteSurveyHazardousWasteRequest): Promise<void> {
        await this.deleteSurveyHazardousWasteRaw(requestParameters);
    }

    /**
     * Finds hazardous waste construction material by id.
     * Find hazardous waste.
     */
    async findSurveyHazardousWasteRaw(requestParameters: FindSurveyHazardousWasteRequest): Promise<runtime.ApiResponse<HazardousWaste>> {
        if (requestParameters.surveyId === null || requestParameters.surveyId === undefined) {
            throw new runtime.RequiredError('surveyId','Required parameter requestParameters.surveyId was null or undefined when calling findSurveyHazardousWaste.');
        }

        if (requestParameters.hazardousWasteId === null || requestParameters.hazardousWasteId === undefined) {
            throw new runtime.RequiredError('hazardousWasteId','Required parameter requestParameters.hazardousWasteId was null or undefined when calling findSurveyHazardousWaste.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/surveys/{surveyId}/hazardousWastes/{hazardousWasteId}`.replace(`{${"surveyId"}}`, encodeURIComponent(String(requestParameters.surveyId))).replace(`{${"hazardousWasteId"}}`, encodeURIComponent(String(requestParameters.hazardousWasteId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => HazardousWasteFromJSON(jsonValue));
    }

    /**
     * Finds hazardous waste construction material by id.
     * Find hazardous waste.
     */
    async findSurveyHazardousWaste(requestParameters: FindSurveyHazardousWasteRequest): Promise<HazardousWaste> {
        const response = await this.findSurveyHazardousWasteRaw(requestParameters);
        return await response.value();
    }

    /**
     * Lists hazardous waste construction material from survey
     * List hazardous waste construction material from survey
     */
    async listSurveyHazardousWastesRaw(requestParameters: ListSurveyHazardousWastesRequest): Promise<runtime.ApiResponse<Array<HazardousWaste>>> {
        if (requestParameters.surveyId === null || requestParameters.surveyId === undefined) {
            throw new runtime.RequiredError('surveyId','Required parameter requestParameters.surveyId was null or undefined when calling listSurveyHazardousWastes.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/surveys/{surveyId}/hazardousWastes`.replace(`{${"surveyId"}}`, encodeURIComponent(String(requestParameters.surveyId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(HazardousWasteFromJSON));
    }

    /**
     * Lists hazardous waste construction material from survey
     * List hazardous waste construction material from survey
     */
    async listSurveyHazardousWastes(requestParameters: ListSurveyHazardousWastesRequest): Promise<Array<HazardousWaste>> {
        const response = await this.listSurveyHazardousWastesRaw(requestParameters);
        return await response.value();
    }

    /**
     * Updates hazardousWaste construction material object.
     * Update hazardousWaste construction material object.
     */
    async updateSurveyHazardousWasteRaw(requestParameters: UpdateSurveyHazardousWasteRequest): Promise<runtime.ApiResponse<HazardousWaste>> {
        if (requestParameters.hazardousWaste === null || requestParameters.hazardousWaste === undefined) {
            throw new runtime.RequiredError('hazardousWaste','Required parameter requestParameters.hazardousWaste was null or undefined when calling updateSurveyHazardousWaste.');
        }

        if (requestParameters.surveyId === null || requestParameters.surveyId === undefined) {
            throw new runtime.RequiredError('surveyId','Required parameter requestParameters.surveyId was null or undefined when calling updateSurveyHazardousWaste.');
        }

        if (requestParameters.hazardousWasteId === null || requestParameters.hazardousWasteId === undefined) {
            throw new runtime.RequiredError('hazardousWasteId','Required parameter requestParameters.hazardousWasteId was null or undefined when calling updateSurveyHazardousWaste.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/surveys/{surveyId}/hazardousWastes/{hazardousWasteId}`.replace(`{${"surveyId"}}`, encodeURIComponent(String(requestParameters.surveyId))).replace(`{${"hazardousWasteId"}}`, encodeURIComponent(String(requestParameters.hazardousWasteId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: HazardousWasteToJSON(requestParameters.hazardousWaste),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => HazardousWasteFromJSON(jsonValue));
    }

    /**
     * Updates hazardousWaste construction material object.
     * Update hazardousWaste construction material object.
     */
    async updateSurveyHazardousWaste(requestParameters: UpdateSurveyHazardousWasteRequest): Promise<HazardousWaste> {
        const response = await this.updateSurveyHazardousWasteRaw(requestParameters);
        return await response.value();
    }

}
