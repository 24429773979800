/* tslint:disable */
/* eslint-disable */
/**
 * Rapurc API-spec
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Metadata,
    MetadataFromJSON,
    MetadataFromJSONTyped,
    MetadataToJSON,
    Unit,
    UnitFromJSON,
    UnitFromJSONTyped,
    UnitToJSON,
    Usability,
    UsabilityFromJSON,
    UsabilityFromJSONTyped,
    UsabilityToJSON,
} from './';

/**
 * Reusable construction material object
 * @export
 * @interface Reusable
 */
export interface Reusable {
    /**
     * 
     * @type {string}
     * @memberof Reusable
     */
    readonly id?: string;
    /**
     * Name of the building component
     * @type {string}
     * @memberof Reusable
     */
    componentName: string;
    /**
     * Reusable material ID
     * @type {string}
     * @memberof Reusable
     */
    reusableMaterialId: string;
    /**
     * 
     * @type {Usability}
     * @memberof Reusable
     */
    usability: Usability;
    /**
     * Amount of reusable material
     * @type {number}
     * @memberof Reusable
     */
    amount?: number;
    /**
     * 
     * @type {Unit}
     * @memberof Reusable
     */
    unit?: Unit;
    /**
     * More detailed description of the reusable material
     * @type {string}
     * @memberof Reusable
     */
    description?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof Reusable
     */
    images?: Array<string>;
    /**
     * Amount of waste
     * @type {number}
     * @memberof Reusable
     */
    amountAsWaste?: number;
    /**
     * 
     * @type {Metadata}
     * @memberof Reusable
     */
    metadata: Metadata;
}

export function ReusableFromJSON(json: any): Reusable {
    return ReusableFromJSONTyped(json, false);
}

export function ReusableFromJSONTyped(json: any, ignoreDiscriminator: boolean): Reusable {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'componentName': json['componentName'],
        'reusableMaterialId': json['reusableMaterialId'],
        'usability': UsabilityFromJSON(json['usability']),
        'amount': !exists(json, 'amount') ? undefined : json['amount'],
        'unit': !exists(json, 'unit') ? undefined : UnitFromJSON(json['unit']),
        'description': !exists(json, 'description') ? undefined : json['description'],
        'images': !exists(json, 'images') ? undefined : json['images'],
        'amountAsWaste': !exists(json, 'amountAsWaste') ? undefined : json['amountAsWaste'],
        'metadata': MetadataFromJSON(json['metadata']),
    };
}

export function ReusableToJSON(value?: Reusable | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'componentName': value.componentName,
        'reusableMaterialId': value.reusableMaterialId,
        'usability': UsabilityToJSON(value.usability),
        'amount': value.amount,
        'unit': UnitToJSON(value.unit),
        'description': value.description,
        'images': value.images,
        'amountAsWaste': value.amountAsWaste,
        'metadata': MetadataToJSON(value.metadata),
    };
}


