/* tslint:disable */
/* eslint-disable */
/**
 * Rapurc API-spec
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Metadata,
    MetadataFromJSON,
    MetadataFromJSONTyped,
    MetadataToJSON,
} from './';

/**
 * Hazardous construction material object
 * @export
 * @interface HazardousWaste
 */
export interface HazardousWaste {
    /**
     * 
     * @type {string}
     * @memberof HazardousWaste
     */
    readonly id?: string;
    /**
     * Hazardous material ID
     * @type {string}
     * @memberof HazardousWaste
     */
    hazardousMaterialId: string;
    /**
     * Waste specifier ID
     * @type {string}
     * @memberof HazardousWaste
     */
    wasteSpecifierId?: string;
    /**
     * Amount of hazardous material (tons)
     * @type {number}
     * @memberof HazardousWaste
     */
    amount: number;
    /**
     * More detailed description of the hazardous material
     * @type {string}
     * @memberof HazardousWaste
     */
    description?: string;
    /**
     * 
     * @type {Metadata}
     * @memberof HazardousWaste
     */
    metadata: Metadata;
}

export function HazardousWasteFromJSON(json: any): HazardousWaste {
    return HazardousWasteFromJSONTyped(json, false);
}

export function HazardousWasteFromJSONTyped(json: any, ignoreDiscriminator: boolean): HazardousWaste {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'hazardousMaterialId': json['hazardousMaterialId'],
        'wasteSpecifierId': !exists(json, 'wasteSpecifierId') ? undefined : json['wasteSpecifierId'],
        'amount': json['amount'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'metadata': MetadataFromJSON(json['metadata']),
    };
}

export function HazardousWasteToJSON(value?: HazardousWaste | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'hazardousMaterialId': value.hazardousMaterialId,
        'wasteSpecifierId': value.wasteSpecifierId,
        'amount': value.amount,
        'description': value.description,
        'metadata': MetadataToJSON(value.metadata),
    };
}


