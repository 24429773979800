/* tslint:disable */
/* eslint-disable */
/**
 * Rapurc API-spec
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    User,
    UserFromJSON,
    UserToJSON,
    UserGroup,
    UserGroupFromJSON,
    UserGroupToJSON,
} from '../models';

export interface CreateUserGroupRequest {
    userGroup: UserGroup;
}

export interface DeleteGroupUserRequest {
    groupId: string;
    userId: string;
}

export interface DeleteUserGroupRequest {
    groupId: string;
}

export interface FindUserGroupRequest {
    groupId: string;
}

export interface ListGroupMembersRequest {
    groupId: string;
}

export interface ListUserGroupsRequest {
    admin?: boolean;
    member?: boolean;
}

export interface UpdateUserGroupRequest {
    userGroup: UserGroup;
    groupId: string;
}

/**
 * 
 */
export class UserGroupsApi extends runtime.BaseAPI {

    /**
     * Creates a user group and assigns the creator as group admin.
     * Create a new group.
     */
    async createUserGroupRaw(requestParameters: CreateUserGroupRequest): Promise<runtime.ApiResponse<UserGroup>> {
        if (requestParameters.userGroup === null || requestParameters.userGroup === undefined) {
            throw new runtime.RequiredError('userGroup','Required parameter requestParameters.userGroup was null or undefined when calling createUserGroup.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/groups`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: UserGroupToJSON(requestParameters.userGroup),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => UserGroupFromJSON(jsonValue));
    }

    /**
     * Creates a user group and assigns the creator as group admin.
     * Create a new group.
     */
    async createUserGroup(requestParameters: CreateUserGroupRequest): Promise<UserGroup> {
        const response = await this.createUserGroupRaw(requestParameters);
        return await response.value();
    }

    /**
     * Deletes user from the group. Only admin can access
     * Delete user from the group.
     */
    async deleteGroupUserRaw(requestParameters: DeleteGroupUserRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.groupId === null || requestParameters.groupId === undefined) {
            throw new runtime.RequiredError('groupId','Required parameter requestParameters.groupId was null or undefined when calling deleteGroupUser.');
        }

        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling deleteGroupUser.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/groups/{groupId}/members/{userId}`.replace(`{${"groupId"}}`, encodeURIComponent(String(requestParameters.groupId))).replace(`{${"userId"}}`, encodeURIComponent(String(requestParameters.userId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Deletes user from the group. Only admin can access
     * Delete user from the group.
     */
    async deleteGroupUser(requestParameters: DeleteGroupUserRequest): Promise<void> {
        await this.deleteGroupUserRaw(requestParameters);
    }

    /**
     * Deletes user group.
     * Delete user group.
     */
    async deleteUserGroupRaw(requestParameters: DeleteUserGroupRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.groupId === null || requestParameters.groupId === undefined) {
            throw new runtime.RequiredError('groupId','Required parameter requestParameters.groupId was null or undefined when calling deleteUserGroup.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/groups/{groupId}`.replace(`{${"groupId"}}`, encodeURIComponent(String(requestParameters.groupId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Deletes user group.
     * Delete user group.
     */
    async deleteUserGroup(requestParameters: DeleteUserGroupRequest): Promise<void> {
        await this.deleteUserGroupRaw(requestParameters);
    }

    /**
     * Finds user group by id.
     * Find user group by id
     */
    async findUserGroupRaw(requestParameters: FindUserGroupRequest): Promise<runtime.ApiResponse<UserGroup>> {
        if (requestParameters.groupId === null || requestParameters.groupId === undefined) {
            throw new runtime.RequiredError('groupId','Required parameter requestParameters.groupId was null or undefined when calling findUserGroup.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/groups/{groupId}`.replace(`{${"groupId"}}`, encodeURIComponent(String(requestParameters.groupId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => UserGroupFromJSON(jsonValue));
    }

    /**
     * Finds user group by id.
     * Find user group by id
     */
    async findUserGroup(requestParameters: FindUserGroupRequest): Promise<UserGroup> {
        const response = await this.findUserGroupRaw(requestParameters);
        return await response.value();
    }

    /**
     * Lists all group join members for the group. Only admin of the group can list it.
     * Lists group members
     */
    async listGroupMembersRaw(requestParameters: ListGroupMembersRequest): Promise<runtime.ApiResponse<Array<User>>> {
        if (requestParameters.groupId === null || requestParameters.groupId === undefined) {
            throw new runtime.RequiredError('groupId','Required parameter requestParameters.groupId was null or undefined when calling listGroupMembers.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/groups/{groupId}/members`.replace(`{${"groupId"}}`, encodeURIComponent(String(requestParameters.groupId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(UserFromJSON));
    }

    /**
     * Lists all group join members for the group. Only admin of the group can list it.
     * Lists group members
     */
    async listGroupMembers(requestParameters: ListGroupMembersRequest): Promise<Array<User>> {
        const response = await this.listGroupMembersRaw(requestParameters);
        return await response.value();
    }

    /**
     * Lists all groups
     * Lists groups
     */
    async listUserGroupsRaw(requestParameters: ListUserGroupsRequest): Promise<runtime.ApiResponse<Array<UserGroup>>> {
        const queryParameters: any = {};

        if (requestParameters.admin !== undefined) {
            queryParameters['admin'] = requestParameters.admin;
        }

        if (requestParameters.member !== undefined) {
            queryParameters['member'] = requestParameters.member;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/groups`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(UserGroupFromJSON));
    }

    /**
     * Lists all groups
     * Lists groups
     */
    async listUserGroups(requestParameters: ListUserGroupsRequest): Promise<Array<UserGroup>> {
        const response = await this.listUserGroupsRaw(requestParameters);
        return await response.value();
    }

    /**
     * Updates user group.
     * Updates user group
     */
    async updateUserGroupRaw(requestParameters: UpdateUserGroupRequest): Promise<runtime.ApiResponse<UserGroup>> {
        if (requestParameters.userGroup === null || requestParameters.userGroup === undefined) {
            throw new runtime.RequiredError('userGroup','Required parameter requestParameters.userGroup was null or undefined when calling updateUserGroup.');
        }

        if (requestParameters.groupId === null || requestParameters.groupId === undefined) {
            throw new runtime.RequiredError('groupId','Required parameter requestParameters.groupId was null or undefined when calling updateUserGroup.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/groups/{groupId}`.replace(`{${"groupId"}}`, encodeURIComponent(String(requestParameters.groupId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UserGroupToJSON(requestParameters.userGroup),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => UserGroupFromJSON(jsonValue));
    }

    /**
     * Updates user group.
     * Updates user group
     */
    async updateUserGroup(requestParameters: UpdateUserGroupRequest): Promise<UserGroup> {
        const response = await this.updateUserGroupRaw(requestParameters);
        return await response.value();
    }

}
