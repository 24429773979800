/* tslint:disable */
/* eslint-disable */
/**
 * Rapurc API-spec
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * Attachment object
 * @export
 * @interface Attachment
 */
export interface Attachment {
    /**
     * 
     * @type {string}
     * @memberof Attachment
     */
    readonly id?: string;
    /**
     * Survey ID
     * @type {string}
     * @memberof Attachment
     */
    surveyId?: string;
    /**
     * URL of the attachment
     * @type {string}
     * @memberof Attachment
     */
    url: string;
    /**
     * Name of the attachement
     * @type {string}
     * @memberof Attachment
     */
    name: string;
    /**
     * Optional description of the attachment
     * @type {string}
     * @memberof Attachment
     */
    description?: string;
}

export function AttachmentFromJSON(json: any): Attachment {
    return AttachmentFromJSONTyped(json, false);
}

export function AttachmentFromJSONTyped(json: any, ignoreDiscriminator: boolean): Attachment {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'surveyId': !exists(json, 'surveyId') ? undefined : json['surveyId'],
        'url': json['url'],
        'name': json['name'],
        'description': !exists(json, 'description') ? undefined : json['description'],
    };
}

export function AttachmentToJSON(value?: Attachment | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'surveyId': value.surveyId,
        'url': value.url,
        'name': value.name,
        'description': value.description,
    };
}


