/* tslint:disable */
/* eslint-disable */
/**
 * Rapurc API-spec
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    GroupJoinRequest,
    GroupJoinRequestFromJSON,
    GroupJoinRequestToJSON,
    JoinRequestStatus,
    JoinRequestStatusFromJSON,
    JoinRequestStatusToJSON,
} from '../models';

export interface CreateGroupJoinRequestRequest {
    groupJoinRequest: GroupJoinRequest;
    groupId: string;
}

export interface DeleteGroupJoinRequestRequest {
    groupId: string;
    requestId: string;
}

export interface FindGroupJoinRequestRequest {
    groupId: string;
    requestId: string;
}

export interface ListGroupJoinRequestsRequest {
    groupId: string;
    status?: JoinRequestStatus;
}

export interface UpdateGroupJoinRequestRequest {
    groupJoinRequest: GroupJoinRequest;
    groupId: string;
    requestId: string;
}

/**
 * 
 */
export class GroupJoinRequestsApi extends runtime.BaseAPI {

    /**
     * Creates a group join request, any user can create it.
     * Create a new group join request.
     */
    async createGroupJoinRequestRaw(requestParameters: CreateGroupJoinRequestRequest): Promise<runtime.ApiResponse<GroupJoinRequest>> {
        if (requestParameters.groupJoinRequest === null || requestParameters.groupJoinRequest === undefined) {
            throw new runtime.RequiredError('groupJoinRequest','Required parameter requestParameters.groupJoinRequest was null or undefined when calling createGroupJoinRequest.');
        }

        if (requestParameters.groupId === null || requestParameters.groupId === undefined) {
            throw new runtime.RequiredError('groupId','Required parameter requestParameters.groupId was null or undefined when calling createGroupJoinRequest.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/groups/{groupId}/requests`.replace(`{${"groupId"}}`, encodeURIComponent(String(requestParameters.groupId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: GroupJoinRequestToJSON(requestParameters.groupJoinRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GroupJoinRequestFromJSON(jsonValue));
    }

    /**
     * Creates a group join request, any user can create it.
     * Create a new group join request.
     */
    async createGroupJoinRequest(requestParameters: CreateGroupJoinRequestRequest): Promise<GroupJoinRequest> {
        const response = await this.createGroupJoinRequestRaw(requestParameters);
        return await response.value();
    }

    /**
     * Deletes user group join request. Can be deleted by group admin only
     * Delete user group join request.
     */
    async deleteGroupJoinRequestRaw(requestParameters: DeleteGroupJoinRequestRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.groupId === null || requestParameters.groupId === undefined) {
            throw new runtime.RequiredError('groupId','Required parameter requestParameters.groupId was null or undefined when calling deleteGroupJoinRequest.');
        }

        if (requestParameters.requestId === null || requestParameters.requestId === undefined) {
            throw new runtime.RequiredError('requestId','Required parameter requestParameters.requestId was null or undefined when calling deleteGroupJoinRequest.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/groups/{groupId}/requests/{requestId}`.replace(`{${"groupId"}}`, encodeURIComponent(String(requestParameters.groupId))).replace(`{${"requestId"}}`, encodeURIComponent(String(requestParameters.requestId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Deletes user group join request. Can be deleted by group admin only
     * Delete user group join request.
     */
    async deleteGroupJoinRequest(requestParameters: DeleteGroupJoinRequestRequest): Promise<void> {
        await this.deleteGroupJoinRequestRaw(requestParameters);
    }

    /**
     * Finds group join request by id. Can be viewed by group admin only
     * Find group join request by id
     */
    async findGroupJoinRequestRaw(requestParameters: FindGroupJoinRequestRequest): Promise<runtime.ApiResponse<GroupJoinRequest>> {
        if (requestParameters.groupId === null || requestParameters.groupId === undefined) {
            throw new runtime.RequiredError('groupId','Required parameter requestParameters.groupId was null or undefined when calling findGroupJoinRequest.');
        }

        if (requestParameters.requestId === null || requestParameters.requestId === undefined) {
            throw new runtime.RequiredError('requestId','Required parameter requestParameters.requestId was null or undefined when calling findGroupJoinRequest.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/groups/{groupId}/requests/{requestId}`.replace(`{${"groupId"}}`, encodeURIComponent(String(requestParameters.groupId))).replace(`{${"requestId"}}`, encodeURIComponent(String(requestParameters.requestId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GroupJoinRequestFromJSON(jsonValue));
    }

    /**
     * Finds group join request by id. Can be viewed by group admin only
     * Find group join request by id
     */
    async findGroupJoinRequest(requestParameters: FindGroupJoinRequestRequest): Promise<GroupJoinRequest> {
        const response = await this.findGroupJoinRequestRaw(requestParameters);
        return await response.value();
    }

    /**
     * Lists all group join requests for the group. Only admin of the group can list join requests.
     * Lists group join requests
     */
    async listGroupJoinRequestsRaw(requestParameters: ListGroupJoinRequestsRequest): Promise<runtime.ApiResponse<Array<GroupJoinRequest>>> {
        if (requestParameters.groupId === null || requestParameters.groupId === undefined) {
            throw new runtime.RequiredError('groupId','Required parameter requestParameters.groupId was null or undefined when calling listGroupJoinRequests.');
        }

        const queryParameters: any = {};

        if (requestParameters.status !== undefined) {
            queryParameters['status'] = requestParameters.status;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/groups/{groupId}/requests`.replace(`{${"groupId"}}`, encodeURIComponent(String(requestParameters.groupId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GroupJoinRequestFromJSON));
    }

    /**
     * Lists all group join requests for the group. Only admin of the group can list join requests.
     * Lists group join requests
     */
    async listGroupJoinRequests(requestParameters: ListGroupJoinRequestsRequest): Promise<Array<GroupJoinRequest>> {
        const response = await this.listGroupJoinRequestsRaw(requestParameters);
        return await response.value();
    }

    /**
     * Updates group join request. Can be updated by group admin only
     * Updates group join request
     */
    async updateGroupJoinRequestRaw(requestParameters: UpdateGroupJoinRequestRequest): Promise<runtime.ApiResponse<GroupJoinRequest>> {
        if (requestParameters.groupJoinRequest === null || requestParameters.groupJoinRequest === undefined) {
            throw new runtime.RequiredError('groupJoinRequest','Required parameter requestParameters.groupJoinRequest was null or undefined when calling updateGroupJoinRequest.');
        }

        if (requestParameters.groupId === null || requestParameters.groupId === undefined) {
            throw new runtime.RequiredError('groupId','Required parameter requestParameters.groupId was null or undefined when calling updateGroupJoinRequest.');
        }

        if (requestParameters.requestId === null || requestParameters.requestId === undefined) {
            throw new runtime.RequiredError('requestId','Required parameter requestParameters.requestId was null or undefined when calling updateGroupJoinRequest.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/groups/{groupId}/requests/{requestId}`.replace(`{${"groupId"}}`, encodeURIComponent(String(requestParameters.groupId))).replace(`{${"requestId"}}`, encodeURIComponent(String(requestParameters.requestId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: GroupJoinRequestToJSON(requestParameters.groupJoinRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GroupJoinRequestFromJSON(jsonValue));
    }

    /**
     * Updates group join request. Can be updated by group admin only
     * Updates group join request
     */
    async updateGroupJoinRequest(requestParameters: UpdateGroupJoinRequestRequest): Promise<GroupJoinRequest> {
        const response = await this.updateGroupJoinRequestRaw(requestParameters);
        return await response.value();
    }

}
