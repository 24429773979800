/* tslint:disable */
/* eslint-disable */
/**
 * Rapurc API-spec
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    Survey,
    SurveyFromJSON,
    SurveyToJSON,
    SurveyStatus,
    SurveyStatusFromJSON,
    SurveyStatusToJSON,
    SurveyType,
    SurveyTypeFromJSON,
    SurveyTypeToJSON,
} from '../models';

export interface CreateSurveyRequest {
    survey: Survey;
}

export interface DeleteSurveyRequest {
    surveyId: string;
}

export interface FindSurveyRequest {
    surveyId: string;
}

export interface ListSurveysRequest {
    firstResult?: number;
    maxResults?: number;
    address?: string;
    status?: SurveyStatus;
    type?: SurveyType;
    dateUnknown?: boolean;
    startDate?: Date;
    endDate?: Date;
}

export interface UpdateSurveyRequest {
    survey: Survey;
    surveyId: string;
}

/**
 * 
 */
export class SurveysApi extends runtime.BaseAPI {

    /**
     * Creates a new survey.
     * Create a survey.
     */
    async createSurveyRaw(requestParameters: CreateSurveyRequest): Promise<runtime.ApiResponse<Survey>> {
        if (requestParameters.survey === null || requestParameters.survey === undefined) {
            throw new runtime.RequiredError('survey','Required parameter requestParameters.survey was null or undefined when calling createSurvey.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/surveys`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SurveyToJSON(requestParameters.survey),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => SurveyFromJSON(jsonValue));
    }

    /**
     * Creates a new survey.
     * Create a survey.
     */
    async createSurvey(requestParameters: CreateSurveyRequest): Promise<Survey> {
        const response = await this.createSurveyRaw(requestParameters);
        return await response.value();
    }

    /**
     * Deletes a survey.
     * Delete a survey.
     */
    async deleteSurveyRaw(requestParameters: DeleteSurveyRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.surveyId === null || requestParameters.surveyId === undefined) {
            throw new runtime.RequiredError('surveyId','Required parameter requestParameters.surveyId was null or undefined when calling deleteSurvey.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/surveys/{surveyId}`.replace(`{${"surveyId"}}`, encodeURIComponent(String(requestParameters.surveyId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Deletes a survey.
     * Delete a survey.
     */
    async deleteSurvey(requestParameters: DeleteSurveyRequest): Promise<void> {
        await this.deleteSurveyRaw(requestParameters);
    }

    /**
     * Finds a survey by id.
     * Find a survey.
     */
    async findSurveyRaw(requestParameters: FindSurveyRequest): Promise<runtime.ApiResponse<Survey>> {
        if (requestParameters.surveyId === null || requestParameters.surveyId === undefined) {
            throw new runtime.RequiredError('surveyId','Required parameter requestParameters.surveyId was null or undefined when calling findSurvey.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/surveys/{surveyId}`.replace(`{${"surveyId"}}`, encodeURIComponent(String(requestParameters.surveyId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => SurveyFromJSON(jsonValue));
    }

    /**
     * Finds a survey by id.
     * Find a survey.
     */
    async findSurvey(requestParameters: FindSurveyRequest): Promise<Survey> {
        const response = await this.findSurveyRaw(requestParameters);
        return await response.value();
    }

    /**
     * Lists surveys.
     * List surveys.
     */
    async listSurveysRaw(requestParameters: ListSurveysRequest): Promise<runtime.ApiResponse<Array<Survey>>> {
        const queryParameters: any = {};

        if (requestParameters.firstResult !== undefined) {
            queryParameters['firstResult'] = requestParameters.firstResult;
        }

        if (requestParameters.maxResults !== undefined) {
            queryParameters['maxResults'] = requestParameters.maxResults;
        }

        if (requestParameters.address !== undefined) {
            queryParameters['address'] = requestParameters.address;
        }

        if (requestParameters.status !== undefined) {
            queryParameters['status'] = requestParameters.status;
        }

        if (requestParameters.type !== undefined) {
            queryParameters['type'] = requestParameters.type;
        }

        if (requestParameters.dateUnknown !== undefined) {
            queryParameters['dateUnknown'] = requestParameters.dateUnknown;
        }

        if (requestParameters.startDate !== undefined) {
            queryParameters['startDate'] = (requestParameters.startDate as any).toISOString().substr(0,10);
        }

        if (requestParameters.endDate !== undefined) {
            queryParameters['endDate'] = (requestParameters.endDate as any).toISOString().substr(0,10);
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/surveys`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(SurveyFromJSON));
    }

    /**
     * Lists surveys.
     * List surveys.
     */
    async listSurveys(requestParameters: ListSurveysRequest): Promise<Array<Survey>> {
        const response = await this.listSurveysRaw(requestParameters);
        return await response.value();
    }

    /**
     * Updates a survey
     * Update a survey.
     */
    async updateSurveyRaw(requestParameters: UpdateSurveyRequest): Promise<runtime.ApiResponse<Survey>> {
        if (requestParameters.survey === null || requestParameters.survey === undefined) {
            throw new runtime.RequiredError('survey','Required parameter requestParameters.survey was null or undefined when calling updateSurvey.');
        }

        if (requestParameters.surveyId === null || requestParameters.surveyId === undefined) {
            throw new runtime.RequiredError('surveyId','Required parameter requestParameters.surveyId was null or undefined when calling updateSurvey.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/surveys/{surveyId}`.replace(`{${"surveyId"}}`, encodeURIComponent(String(requestParameters.surveyId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: SurveyToJSON(requestParameters.survey),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => SurveyFromJSON(jsonValue));
    }

    /**
     * Updates a survey
     * Update a survey.
     */
    async updateSurvey(requestParameters: UpdateSurveyRequest): Promise<Survey> {
        const response = await this.updateSurveyRaw(requestParameters);
        return await response.value();
    }

}
