/* tslint:disable */
/* eslint-disable */
/**
 * Rapurc API-spec
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    WasteCategory,
    WasteCategoryFromJSON,
    WasteCategoryToJSON,
} from '../models';

export interface CreateWasteCategoryRequest {
    wasteCategory: WasteCategory;
}

export interface DeleteWasteCategoryRequest {
    wasteCategoryId: string;
}

export interface FindWasteCategoryRequest {
    wasteCategoryId: string;
}

export interface UpdateWasteCategoryRequest {
    wasteCategory: WasteCategory;
    wasteCategoryId: string;
}

/**
 * 
 */
export class WasteCategoryApi extends runtime.BaseAPI {

    /**
     * Creates a new waste category.
     * Create a new waste category.
     */
    async createWasteCategoryRaw(requestParameters: CreateWasteCategoryRequest): Promise<runtime.ApiResponse<WasteCategory>> {
        if (requestParameters.wasteCategory === null || requestParameters.wasteCategory === undefined) {
            throw new runtime.RequiredError('wasteCategory','Required parameter requestParameters.wasteCategory was null or undefined when calling createWasteCategory.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/wasteCategories`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: WasteCategoryToJSON(requestParameters.wasteCategory),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => WasteCategoryFromJSON(jsonValue));
    }

    /**
     * Creates a new waste category.
     * Create a new waste category.
     */
    async createWasteCategory(requestParameters: CreateWasteCategoryRequest): Promise<WasteCategory> {
        const response = await this.createWasteCategoryRaw(requestParameters);
        return await response.value();
    }

    /**
     * Deletes waste category.
     * Delete waste category.
     */
    async deleteWasteCategoryRaw(requestParameters: DeleteWasteCategoryRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.wasteCategoryId === null || requestParameters.wasteCategoryId === undefined) {
            throw new runtime.RequiredError('wasteCategoryId','Required parameter requestParameters.wasteCategoryId was null or undefined when calling deleteWasteCategory.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/WasteCategories/{WasteCategoryId}`.replace(`{${"WasteCategoryId"}}`, encodeURIComponent(String(requestParameters.wasteCategoryId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Deletes waste category.
     * Delete waste category.
     */
    async deleteWasteCategory(requestParameters: DeleteWasteCategoryRequest): Promise<void> {
        await this.deleteWasteCategoryRaw(requestParameters);
    }

    /**
     * Finds waste category by id.
     * Find waste category.
     */
    async findWasteCategoryRaw(requestParameters: FindWasteCategoryRequest): Promise<runtime.ApiResponse<WasteCategory>> {
        if (requestParameters.wasteCategoryId === null || requestParameters.wasteCategoryId === undefined) {
            throw new runtime.RequiredError('wasteCategoryId','Required parameter requestParameters.wasteCategoryId was null or undefined when calling findWasteCategory.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/WasteCategories/{WasteCategoryId}`.replace(`{${"WasteCategoryId"}}`, encodeURIComponent(String(requestParameters.wasteCategoryId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => WasteCategoryFromJSON(jsonValue));
    }

    /**
     * Finds waste category by id.
     * Find waste category.
     */
    async findWasteCategory(requestParameters: FindWasteCategoryRequest): Promise<WasteCategory> {
        const response = await this.findWasteCategoryRaw(requestParameters);
        return await response.value();
    }

    /**
     * Lists waste categories. Results are in alphabetical order
     * List waste categories
     */
    async listWasteCategoriesRaw(): Promise<runtime.ApiResponse<Array<WasteCategory>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/wasteCategories`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(WasteCategoryFromJSON));
    }

    /**
     * Lists waste categories. Results are in alphabetical order
     * List waste categories
     */
    async listWasteCategories(): Promise<Array<WasteCategory>> {
        const response = await this.listWasteCategoriesRaw();
        return await response.value();
    }

    /**
     * Updates waste category object.
     * Update waste category object.
     */
    async updateWasteCategoryRaw(requestParameters: UpdateWasteCategoryRequest): Promise<runtime.ApiResponse<WasteCategory>> {
        if (requestParameters.wasteCategory === null || requestParameters.wasteCategory === undefined) {
            throw new runtime.RequiredError('wasteCategory','Required parameter requestParameters.wasteCategory was null or undefined when calling updateWasteCategory.');
        }

        if (requestParameters.wasteCategoryId === null || requestParameters.wasteCategoryId === undefined) {
            throw new runtime.RequiredError('wasteCategoryId','Required parameter requestParameters.wasteCategoryId was null or undefined when calling updateWasteCategory.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/WasteCategories/{WasteCategoryId}`.replace(`{${"WasteCategoryId"}}`, encodeURIComponent(String(requestParameters.wasteCategoryId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: WasteCategoryToJSON(requestParameters.wasteCategory),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => WasteCategoryFromJSON(jsonValue));
    }

    /**
     * Updates waste category object.
     * Update waste category object.
     */
    async updateWasteCategory(requestParameters: UpdateWasteCategoryRequest): Promise<WasteCategory> {
        const response = await this.updateWasteCategoryRaw(requestParameters);
        return await response.value();
    }

}
