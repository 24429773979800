/* tslint:disable */
/* eslint-disable */
/**
 * Rapurc API-spec
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    WasteSpecifier,
    WasteSpecifierFromJSON,
    WasteSpecifierToJSON,
} from '../models';

export interface CreateWasteSpecifierRequest {
    wasteSpecifier: WasteSpecifier;
}

export interface DeleteWasteSpecifierRequest {
    wasteSpecifierId: string;
}

export interface FindWasteSpecifierRequest {
    wasteSpecifierId: string;
}

export interface UpdateWasteSpecifierRequest {
    wasteSpecifier: WasteSpecifier;
    wasteSpecifierId: string;
}

/**
 * 
 */
export class WasteSpecifiersApi extends runtime.BaseAPI {

    /**
     * Creates a new waste specifiers.
     * Create a waste specifiers.
     */
    async createWasteSpecifierRaw(requestParameters: CreateWasteSpecifierRequest): Promise<runtime.ApiResponse<WasteSpecifier>> {
        if (requestParameters.wasteSpecifier === null || requestParameters.wasteSpecifier === undefined) {
            throw new runtime.RequiredError('wasteSpecifier','Required parameter requestParameters.wasteSpecifier was null or undefined when calling createWasteSpecifier.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/wasteSpecifiers`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: WasteSpecifierToJSON(requestParameters.wasteSpecifier),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => WasteSpecifierFromJSON(jsonValue));
    }

    /**
     * Creates a new waste specifiers.
     * Create a waste specifiers.
     */
    async createWasteSpecifier(requestParameters: CreateWasteSpecifierRequest): Promise<WasteSpecifier> {
        const response = await this.createWasteSpecifierRaw(requestParameters);
        return await response.value();
    }

    /**
     * Deletes a waste specifiers.
     * Delete a waste specifiers.
     */
    async deleteWasteSpecifierRaw(requestParameters: DeleteWasteSpecifierRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.wasteSpecifierId === null || requestParameters.wasteSpecifierId === undefined) {
            throw new runtime.RequiredError('wasteSpecifierId','Required parameter requestParameters.wasteSpecifierId was null or undefined when calling deleteWasteSpecifier.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/wasteSpecifiers/{wasteSpecifierId}`.replace(`{${"wasteSpecifierId"}}`, encodeURIComponent(String(requestParameters.wasteSpecifierId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Deletes a waste specifiers.
     * Delete a waste specifiers.
     */
    async deleteWasteSpecifier(requestParameters: DeleteWasteSpecifierRequest): Promise<void> {
        await this.deleteWasteSpecifierRaw(requestParameters);
    }

    /**
     * Finds a waste specifiers by id.
     * Find a waste specifiers.
     */
    async findWasteSpecifierRaw(requestParameters: FindWasteSpecifierRequest): Promise<runtime.ApiResponse<WasteSpecifier>> {
        if (requestParameters.wasteSpecifierId === null || requestParameters.wasteSpecifierId === undefined) {
            throw new runtime.RequiredError('wasteSpecifierId','Required parameter requestParameters.wasteSpecifierId was null or undefined when calling findWasteSpecifier.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/wasteSpecifiers/{wasteSpecifierId}`.replace(`{${"wasteSpecifierId"}}`, encodeURIComponent(String(requestParameters.wasteSpecifierId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => WasteSpecifierFromJSON(jsonValue));
    }

    /**
     * Finds a waste specifiers by id.
     * Find a waste specifiers.
     */
    async findWasteSpecifier(requestParameters: FindWasteSpecifierRequest): Promise<WasteSpecifier> {
        const response = await this.findWasteSpecifierRaw(requestParameters);
        return await response.value();
    }

    /**
     * Lists waste specifiers.
     * List waste specifiers.
     */
    async listWasteSpecifiersRaw(): Promise<runtime.ApiResponse<Array<WasteSpecifier>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/wasteSpecifiers`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(WasteSpecifierFromJSON));
    }

    /**
     * Lists waste specifiers.
     * List waste specifiers.
     */
    async listWasteSpecifiers(): Promise<Array<WasteSpecifier>> {
        const response = await this.listWasteSpecifiersRaw();
        return await response.value();
    }

    /**
     * Updates a waste specifiers
     * Update a waste specifiers.
     */
    async updateWasteSpecifierRaw(requestParameters: UpdateWasteSpecifierRequest): Promise<runtime.ApiResponse<WasteSpecifier>> {
        if (requestParameters.wasteSpecifier === null || requestParameters.wasteSpecifier === undefined) {
            throw new runtime.RequiredError('wasteSpecifier','Required parameter requestParameters.wasteSpecifier was null or undefined when calling updateWasteSpecifier.');
        }

        if (requestParameters.wasteSpecifierId === null || requestParameters.wasteSpecifierId === undefined) {
            throw new runtime.RequiredError('wasteSpecifierId','Required parameter requestParameters.wasteSpecifierId was null or undefined when calling updateWasteSpecifier.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/wasteSpecifiers/{wasteSpecifierId}`.replace(`{${"wasteSpecifierId"}}`, encodeURIComponent(String(requestParameters.wasteSpecifierId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: WasteSpecifierToJSON(requestParameters.wasteSpecifier),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => WasteSpecifierFromJSON(jsonValue));
    }

    /**
     * Updates a waste specifiers
     * Update a waste specifiers.
     */
    async updateWasteSpecifier(requestParameters: UpdateWasteSpecifierRequest): Promise<WasteSpecifier> {
        const response = await this.updateWasteSpecifierRaw(requestParameters);
        return await response.value();
    }

}
