/* tslint:disable */
/* eslint-disable */
/**
 * Rapurc API-spec
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Address,
    AddressFromJSON,
    AddressFromJSONTyped,
    AddressToJSON,
    Metadata,
    MetadataFromJSON,
    MetadataFromJSONTyped,
    MetadataToJSON,
    OtherStructure,
    OtherStructureFromJSON,
    OtherStructureFromJSONTyped,
    OtherStructureToJSON,
} from './';

/**
 * Information about the building
 * @export
 * @interface Building
 */
export interface Building {
    /**
     * Id of the building information object
     * @type {string}
     * @memberof Building
     */
    readonly id?: string;
    /**
     * Id of the survey
     * @type {string}
     * @memberof Building
     */
    surveyId: string;
    /**
     * Property id
     * @type {string}
     * @memberof Building
     */
    propertyId?: string;
    /**
     * Building id
     * @type {string}
     * @memberof Building
     */
    buildingId?: string;
    /**
     * Building type id
     * @type {string}
     * @memberof Building
     */
    buildingTypeId?: string;
    /**
     * Construction year
     * @type {number}
     * @memberof Building
     */
    constructionYear?: number;
    /**
     * Space of the building in square meters
     * @type {number}
     * @memberof Building
     */
    space?: number;
    /**
     * Volume of the building in qubic meters
     * @type {number}
     * @memberof Building
     */
    volume?: number;
    /**
     * Number of floors
     * @type {string}
     * @memberof Building
     */
    floors?: string;
    /**
     * Number of basements
     * @type {number}
     * @memberof Building
     */
    basements?: number;
    /**
     * Material of foundation
     * @type {string}
     * @memberof Building
     */
    foundation?: string;
    /**
     * Supporting structure
     * @type {string}
     * @memberof Building
     */
    supportingStructure?: string;
    /**
     * Facade material
     * @type {string}
     * @memberof Building
     */
    facadeMaterial?: string;
    /**
     * Roof type
     * @type {string}
     * @memberof Building
     */
    roofType?: string;
    /**
     * Name of the property
     * @type {string}
     * @memberof Building
     */
    propertyName?: string;
    /**
     * 
     * @type {Address}
     * @memberof Building
     */
    address?: Address;
    /**
     * List of other structures to be demolished
     * @type {Array<OtherStructure>}
     * @memberof Building
     */
    otherStructures?: Array<OtherStructure>;
    /**
     * 
     * @type {Metadata}
     * @memberof Building
     */
    metadata: Metadata;
}

export function BuildingFromJSON(json: any): Building {
    return BuildingFromJSONTyped(json, false);
}

export function BuildingFromJSONTyped(json: any, ignoreDiscriminator: boolean): Building {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'surveyId': json['surveyId'],
        'propertyId': !exists(json, 'propertyId') ? undefined : json['propertyId'],
        'buildingId': !exists(json, 'buildingId') ? undefined : json['buildingId'],
        'buildingTypeId': !exists(json, 'buildingTypeId') ? undefined : json['buildingTypeId'],
        'constructionYear': !exists(json, 'constructionYear') ? undefined : json['constructionYear'],
        'space': !exists(json, 'space') ? undefined : json['space'],
        'volume': !exists(json, 'volume') ? undefined : json['volume'],
        'floors': !exists(json, 'floors') ? undefined : json['floors'],
        'basements': !exists(json, 'basements') ? undefined : json['basements'],
        'foundation': !exists(json, 'foundation') ? undefined : json['foundation'],
        'supportingStructure': !exists(json, 'supportingStructure') ? undefined : json['supportingStructure'],
        'facadeMaterial': !exists(json, 'facadeMaterial') ? undefined : json['facadeMaterial'],
        'roofType': !exists(json, 'roofType') ? undefined : json['roofType'],
        'propertyName': !exists(json, 'propertyName') ? undefined : json['propertyName'],
        'address': !exists(json, 'address') ? undefined : AddressFromJSON(json['address']),
        'otherStructures': !exists(json, 'otherStructures') ? undefined : ((json['otherStructures'] as Array<any>).map(OtherStructureFromJSON)),
        'metadata': MetadataFromJSON(json['metadata']),
    };
}

export function BuildingToJSON(value?: Building | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'surveyId': value.surveyId,
        'propertyId': value.propertyId,
        'buildingId': value.buildingId,
        'buildingTypeId': value.buildingTypeId,
        'constructionYear': value.constructionYear,
        'space': value.space,
        'volume': value.volume,
        'floors': value.floors,
        'basements': value.basements,
        'foundation': value.foundation,
        'supportingStructure': value.supportingStructure,
        'facadeMaterial': value.facadeMaterial,
        'roofType': value.roofType,
        'propertyName': value.propertyName,
        'address': AddressToJSON(value.address),
        'otherStructures': value.otherStructures === undefined ? undefined : ((value.otherStructures as Array<any>).map(OtherStructureToJSON)),
        'metadata': MetadataToJSON(value.metadata),
    };
}


