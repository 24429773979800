/* tslint:disable */
/* eslint-disable */
/**
 * Rapurc API-spec
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    Usage,
    UsageFromJSON,
    UsageToJSON,
} from '../models';

export interface CreateUsageRequest {
    usage: Usage;
}

export interface DeleteUsageRequest {
    usageId: string;
}

export interface FindUsageRequest {
    usageId: string;
}

export interface UpdateUsageRequest {
    usage: Usage;
    usageId: string;
}

/**
 * 
 */
export class UsagesApi extends runtime.BaseAPI {

    /**
     * Creates a new usage.
     * Create a usage.
     */
    async createUsageRaw(requestParameters: CreateUsageRequest): Promise<runtime.ApiResponse<Usage>> {
        if (requestParameters.usage === null || requestParameters.usage === undefined) {
            throw new runtime.RequiredError('usage','Required parameter requestParameters.usage was null or undefined when calling createUsage.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/usages`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: UsageToJSON(requestParameters.usage),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => UsageFromJSON(jsonValue));
    }

    /**
     * Creates a new usage.
     * Create a usage.
     */
    async createUsage(requestParameters: CreateUsageRequest): Promise<Usage> {
        const response = await this.createUsageRaw(requestParameters);
        return await response.value();
    }

    /**
     * Deletes a usage.
     * Delete a usage.
     */
    async deleteUsageRaw(requestParameters: DeleteUsageRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.usageId === null || requestParameters.usageId === undefined) {
            throw new runtime.RequiredError('usageId','Required parameter requestParameters.usageId was null or undefined when calling deleteUsage.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/usages/{usageId}`.replace(`{${"usageId"}}`, encodeURIComponent(String(requestParameters.usageId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Deletes a usage.
     * Delete a usage.
     */
    async deleteUsage(requestParameters: DeleteUsageRequest): Promise<void> {
        await this.deleteUsageRaw(requestParameters);
    }

    /**
     * Finds a usage by id.
     * Find a usage.
     */
    async findUsageRaw(requestParameters: FindUsageRequest): Promise<runtime.ApiResponse<Usage>> {
        if (requestParameters.usageId === null || requestParameters.usageId === undefined) {
            throw new runtime.RequiredError('usageId','Required parameter requestParameters.usageId was null or undefined when calling findUsage.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/usages/{usageId}`.replace(`{${"usageId"}}`, encodeURIComponent(String(requestParameters.usageId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => UsageFromJSON(jsonValue));
    }

    /**
     * Finds a usage by id.
     * Find a usage.
     */
    async findUsage(requestParameters: FindUsageRequest): Promise<Usage> {
        const response = await this.findUsageRaw(requestParameters);
        return await response.value();
    }

    /**
     * Lists usages.
     * List usages.
     */
    async listUsagesRaw(): Promise<runtime.ApiResponse<Array<Usage>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/usages`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(UsageFromJSON));
    }

    /**
     * Lists usages.
     * List usages.
     */
    async listUsages(): Promise<Array<Usage>> {
        const response = await this.listUsagesRaw();
        return await response.value();
    }

    /**
     * Updates a usage
     * Update a usage.
     */
    async updateUsageRaw(requestParameters: UpdateUsageRequest): Promise<runtime.ApiResponse<Usage>> {
        if (requestParameters.usage === null || requestParameters.usage === undefined) {
            throw new runtime.RequiredError('usage','Required parameter requestParameters.usage was null or undefined when calling updateUsage.');
        }

        if (requestParameters.usageId === null || requestParameters.usageId === undefined) {
            throw new runtime.RequiredError('usageId','Required parameter requestParameters.usageId was null or undefined when calling updateUsage.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/usages/{usageId}`.replace(`{${"usageId"}}`, encodeURIComponent(String(requestParameters.usageId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UsageToJSON(requestParameters.usage),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => UsageFromJSON(jsonValue));
    }

    /**
     * Updates a usage
     * Update a usage.
     */
    async updateUsage(requestParameters: UpdateUsageRequest): Promise<Usage> {
        const response = await this.updateUsageRaw(requestParameters);
        return await response.value();
    }

}
