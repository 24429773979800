/* tslint:disable */
/* eslint-disable */
/**
 * Rapurc API-spec
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Metadata,
    MetadataFromJSON,
    MetadataFromJSONTyped,
    MetadataToJSON,
    SurveyStatus,
    SurveyStatusFromJSON,
    SurveyStatusFromJSONTyped,
    SurveyStatusToJSON,
    SurveyType,
    SurveyTypeFromJSON,
    SurveyTypeFromJSONTyped,
    SurveyTypeToJSON,
} from './';

/**
 * Survey object
 * @export
 * @interface Survey
 */
export interface Survey {
    /**
     * ID of the survey
     * @type {string}
     * @memberof Survey
     */
    readonly id?: string;
    /**
     * 
     * @type {SurveyStatus}
     * @memberof Survey
     */
    status: SurveyStatus;
    /**
     * 
     * @type {SurveyType}
     * @memberof Survey
     */
    type: SurveyType;
    /**
     * True if date of demolition is unknown
     * @type {boolean}
     * @memberof Survey
     */
    dateUnknown?: boolean;
    /**
     * Estimated start date of demolition. Accuracy is one month
     * @type {Date}
     * @memberof Survey
     */
    startDate?: Date;
    /**
     * Estimated end date of demolition. Accuracy is one month
     * @type {Date}
     * @memberof Survey
     */
    endDate?: Date;
    /**
     * Additional information notes
     * @type {string}
     * @memberof Survey
     */
    additionalInformation?: string;
    /**
     * Time when the survey status was marked as DONE, null if survey is not in DONE state
     * @type {Date}
     * @memberof Survey
     */
    markedAsDone?: Date;
    /**
     * Formatted full name or username of the creator of the survey
     * @type {string}
     * @memberof Survey
     */
    readonly creatorDisplayName?: string;
    /**
     * ID of the user group
     * @type {string}
     * @memberof Survey
     */
    groupId: string;
    /**
     * 
     * @type {Metadata}
     * @memberof Survey
     */
    metadata: Metadata;
}

export function SurveyFromJSON(json: any): Survey {
    return SurveyFromJSONTyped(json, false);
}

export function SurveyFromJSONTyped(json: any, ignoreDiscriminator: boolean): Survey {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'status': SurveyStatusFromJSON(json['status']),
        'type': SurveyTypeFromJSON(json['type']),
        'dateUnknown': !exists(json, 'dateUnknown') ? undefined : json['dateUnknown'],
        'startDate': !exists(json, 'startDate') ? undefined : (new Date(json['startDate'])),
        'endDate': !exists(json, 'endDate') ? undefined : (new Date(json['endDate'])),
        'additionalInformation': !exists(json, 'additionalInformation') ? undefined : json['additionalInformation'],
        'markedAsDone': !exists(json, 'markedAsDone') ? undefined : (new Date(json['markedAsDone'])),
        'creatorDisplayName': !exists(json, 'creatorDisplayName') ? undefined : json['creatorDisplayName'],
        'groupId': json['groupId'],
        'metadata': MetadataFromJSON(json['metadata']),
    };
}

export function SurveyToJSON(value?: Survey | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'status': SurveyStatusToJSON(value.status),
        'type': SurveyTypeToJSON(value.type),
        'dateUnknown': value.dateUnknown,
        'startDate': value.startDate === undefined ? undefined : (value.startDate.toISOString().substr(0,10)),
        'endDate': value.endDate === undefined ? undefined : (value.endDate.toISOString().substr(0,10)),
        'additionalInformation': value.additionalInformation,
        'markedAsDone': value.markedAsDone === undefined ? undefined : (value.markedAsDone.toISOString()),
        'groupId': value.groupId,
        'metadata': MetadataToJSON(value.metadata),
    };
}


