/* tslint:disable */
/* eslint-disable */
/**
 * Rapurc API-spec
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    Waste,
    WasteFromJSON,
    WasteToJSON,
} from '../models';

export interface CreateSurveyWasteRequest {
    waste: Waste;
    surveyId: string;
}

export interface DeleteSurveyWasteRequest {
    surveyId: string;
    wasteId: string;
}

export interface FindSurveyWasteRequest {
    surveyId: string;
    wasteId: string;
}

export interface ListSurveyWastesRequest {
    surveyId: string;
}

export interface UpdateSurveyWasteRequest {
    waste: Waste;
    surveyId: string;
    wasteId: string;
}

/**
 * 
 */
export class SurveyWastesApi extends runtime.BaseAPI {

    /**
     * Creates a new waste construction material object.
     * Create a new waste construction material object.
     */
    async createSurveyWasteRaw(requestParameters: CreateSurveyWasteRequest): Promise<runtime.ApiResponse<Waste>> {
        if (requestParameters.waste === null || requestParameters.waste === undefined) {
            throw new runtime.RequiredError('waste','Required parameter requestParameters.waste was null or undefined when calling createSurveyWaste.');
        }

        if (requestParameters.surveyId === null || requestParameters.surveyId === undefined) {
            throw new runtime.RequiredError('surveyId','Required parameter requestParameters.surveyId was null or undefined when calling createSurveyWaste.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/surveys/{surveyId}/wastes`.replace(`{${"surveyId"}}`, encodeURIComponent(String(requestParameters.surveyId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: WasteToJSON(requestParameters.waste),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => WasteFromJSON(jsonValue));
    }

    /**
     * Creates a new waste construction material object.
     * Create a new waste construction material object.
     */
    async createSurveyWaste(requestParameters: CreateSurveyWasteRequest): Promise<Waste> {
        const response = await this.createSurveyWasteRaw(requestParameters);
        return await response.value();
    }

    /**
     * Deletes waste construction material from survey
     * Delete waste construction material from survey
     */
    async deleteSurveyWasteRaw(requestParameters: DeleteSurveyWasteRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.surveyId === null || requestParameters.surveyId === undefined) {
            throw new runtime.RequiredError('surveyId','Required parameter requestParameters.surveyId was null or undefined when calling deleteSurveyWaste.');
        }

        if (requestParameters.wasteId === null || requestParameters.wasteId === undefined) {
            throw new runtime.RequiredError('wasteId','Required parameter requestParameters.wasteId was null or undefined when calling deleteSurveyWaste.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/surveys/{surveyId}/wastes/{wasteId}`.replace(`{${"surveyId"}}`, encodeURIComponent(String(requestParameters.surveyId))).replace(`{${"wasteId"}}`, encodeURIComponent(String(requestParameters.wasteId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Deletes waste construction material from survey
     * Delete waste construction material from survey
     */
    async deleteSurveyWaste(requestParameters: DeleteSurveyWasteRequest): Promise<void> {
        await this.deleteSurveyWasteRaw(requestParameters);
    }

    /**
     * Finds waste construction material by id.
     * Find waste.
     */
    async findSurveyWasteRaw(requestParameters: FindSurveyWasteRequest): Promise<runtime.ApiResponse<Waste>> {
        if (requestParameters.surveyId === null || requestParameters.surveyId === undefined) {
            throw new runtime.RequiredError('surveyId','Required parameter requestParameters.surveyId was null or undefined when calling findSurveyWaste.');
        }

        if (requestParameters.wasteId === null || requestParameters.wasteId === undefined) {
            throw new runtime.RequiredError('wasteId','Required parameter requestParameters.wasteId was null or undefined when calling findSurveyWaste.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/surveys/{surveyId}/wastes/{wasteId}`.replace(`{${"surveyId"}}`, encodeURIComponent(String(requestParameters.surveyId))).replace(`{${"wasteId"}}`, encodeURIComponent(String(requestParameters.wasteId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => WasteFromJSON(jsonValue));
    }

    /**
     * Finds waste construction material by id.
     * Find waste.
     */
    async findSurveyWaste(requestParameters: FindSurveyWasteRequest): Promise<Waste> {
        const response = await this.findSurveyWasteRaw(requestParameters);
        return await response.value();
    }

    /**
     * Lists waste construction material from survey
     * List waste construction material from survey
     */
    async listSurveyWastesRaw(requestParameters: ListSurveyWastesRequest): Promise<runtime.ApiResponse<Array<Waste>>> {
        if (requestParameters.surveyId === null || requestParameters.surveyId === undefined) {
            throw new runtime.RequiredError('surveyId','Required parameter requestParameters.surveyId was null or undefined when calling listSurveyWastes.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/surveys/{surveyId}/wastes`.replace(`{${"surveyId"}}`, encodeURIComponent(String(requestParameters.surveyId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(WasteFromJSON));
    }

    /**
     * Lists waste construction material from survey
     * List waste construction material from survey
     */
    async listSurveyWastes(requestParameters: ListSurveyWastesRequest): Promise<Array<Waste>> {
        const response = await this.listSurveyWastesRaw(requestParameters);
        return await response.value();
    }

    /**
     * Updates waste construction material object.
     * Update waste construction material object.
     */
    async updateSurveyWasteRaw(requestParameters: UpdateSurveyWasteRequest): Promise<runtime.ApiResponse<Waste>> {
        if (requestParameters.waste === null || requestParameters.waste === undefined) {
            throw new runtime.RequiredError('waste','Required parameter requestParameters.waste was null or undefined when calling updateSurveyWaste.');
        }

        if (requestParameters.surveyId === null || requestParameters.surveyId === undefined) {
            throw new runtime.RequiredError('surveyId','Required parameter requestParameters.surveyId was null or undefined when calling updateSurveyWaste.');
        }

        if (requestParameters.wasteId === null || requestParameters.wasteId === undefined) {
            throw new runtime.RequiredError('wasteId','Required parameter requestParameters.wasteId was null or undefined when calling updateSurveyWaste.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/surveys/{surveyId}/wastes/{wasteId}`.replace(`{${"surveyId"}}`, encodeURIComponent(String(requestParameters.surveyId))).replace(`{${"wasteId"}}`, encodeURIComponent(String(requestParameters.wasteId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: WasteToJSON(requestParameters.waste),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => WasteFromJSON(jsonValue));
    }

    /**
     * Updates waste construction material object.
     * Update waste construction material object.
     */
    async updateSurveyWaste(requestParameters: UpdateSurveyWasteRequest): Promise<Waste> {
        const response = await this.updateSurveyWasteRaw(requestParameters);
        return await response.value();
    }

}
