/* tslint:disable */
/* eslint-disable */
/**
 * Rapurc API-spec
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    OwnerInformation,
    OwnerInformationFromJSON,
    OwnerInformationToJSON,
} from '../models';

export interface CreateOwnerInformationRequest {
    ownerInformation: OwnerInformation;
    surveyId: string;
}

export interface DeleteOwnerInformationRequest {
    surveyId: string;
    ownerId: string;
}

export interface FindOwnerInformationRequest {
    surveyId: string;
    ownerId: string;
}

export interface ListOwnerInformationRequest {
    surveyId: string;
}

export interface UpdateOwnerInformationRequest {
    ownerInformation: OwnerInformation;
    surveyId: string;
    ownerId: string;
}

/**
 * 
 */
export class OwnersApi extends runtime.BaseAPI {

    /**
     * Creates a new owner information object.
     * Create a new owner information object.
     */
    async createOwnerInformationRaw(requestParameters: CreateOwnerInformationRequest): Promise<runtime.ApiResponse<OwnerInformation>> {
        if (requestParameters.ownerInformation === null || requestParameters.ownerInformation === undefined) {
            throw new runtime.RequiredError('ownerInformation','Required parameter requestParameters.ownerInformation was null or undefined when calling createOwnerInformation.');
        }

        if (requestParameters.surveyId === null || requestParameters.surveyId === undefined) {
            throw new runtime.RequiredError('surveyId','Required parameter requestParameters.surveyId was null or undefined when calling createOwnerInformation.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/surveys/{surveyId}/owners`.replace(`{${"surveyId"}}`, encodeURIComponent(String(requestParameters.surveyId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: OwnerInformationToJSON(requestParameters.ownerInformation),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => OwnerInformationFromJSON(jsonValue));
    }

    /**
     * Creates a new owner information object.
     * Create a new owner information object.
     */
    async createOwnerInformation(requestParameters: CreateOwnerInformationRequest): Promise<OwnerInformation> {
        const response = await this.createOwnerInformationRaw(requestParameters);
        return await response.value();
    }

    /**
     * Deletes owner information.
     * Delete owner information.
     */
    async deleteOwnerInformationRaw(requestParameters: DeleteOwnerInformationRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.surveyId === null || requestParameters.surveyId === undefined) {
            throw new runtime.RequiredError('surveyId','Required parameter requestParameters.surveyId was null or undefined when calling deleteOwnerInformation.');
        }

        if (requestParameters.ownerId === null || requestParameters.ownerId === undefined) {
            throw new runtime.RequiredError('ownerId','Required parameter requestParameters.ownerId was null or undefined when calling deleteOwnerInformation.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/surveys/{surveyId}/owners/{ownerId}`.replace(`{${"surveyId"}}`, encodeURIComponent(String(requestParameters.surveyId))).replace(`{${"ownerId"}}`, encodeURIComponent(String(requestParameters.ownerId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Deletes owner information.
     * Delete owner information.
     */
    async deleteOwnerInformation(requestParameters: DeleteOwnerInformationRequest): Promise<void> {
        await this.deleteOwnerInformationRaw(requestParameters);
    }

    /**
     * Finds owner information by id.
     * Find owner information.
     */
    async findOwnerInformationRaw(requestParameters: FindOwnerInformationRequest): Promise<runtime.ApiResponse<OwnerInformation>> {
        if (requestParameters.surveyId === null || requestParameters.surveyId === undefined) {
            throw new runtime.RequiredError('surveyId','Required parameter requestParameters.surveyId was null or undefined when calling findOwnerInformation.');
        }

        if (requestParameters.ownerId === null || requestParameters.ownerId === undefined) {
            throw new runtime.RequiredError('ownerId','Required parameter requestParameters.ownerId was null or undefined when calling findOwnerInformation.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/surveys/{surveyId}/owners/{ownerId}`.replace(`{${"surveyId"}}`, encodeURIComponent(String(requestParameters.surveyId))).replace(`{${"ownerId"}}`, encodeURIComponent(String(requestParameters.ownerId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => OwnerInformationFromJSON(jsonValue));
    }

    /**
     * Finds owner information by id.
     * Find owner information.
     */
    async findOwnerInformation(requestParameters: FindOwnerInformationRequest): Promise<OwnerInformation> {
        const response = await this.findOwnerInformationRaw(requestParameters);
        return await response.value();
    }

    /**
     * Lists owner information.
     * List owner information.
     */
    async listOwnerInformationRaw(requestParameters: ListOwnerInformationRequest): Promise<runtime.ApiResponse<Array<OwnerInformation>>> {
        if (requestParameters.surveyId === null || requestParameters.surveyId === undefined) {
            throw new runtime.RequiredError('surveyId','Required parameter requestParameters.surveyId was null or undefined when calling listOwnerInformation.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/surveys/{surveyId}/owners`.replace(`{${"surveyId"}}`, encodeURIComponent(String(requestParameters.surveyId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(OwnerInformationFromJSON));
    }

    /**
     * Lists owner information.
     * List owner information.
     */
    async listOwnerInformation(requestParameters: ListOwnerInformationRequest): Promise<Array<OwnerInformation>> {
        const response = await this.listOwnerInformationRaw(requestParameters);
        return await response.value();
    }

    /**
     * Updates owner information object.
     * Update owner information object.
     */
    async updateOwnerInformationRaw(requestParameters: UpdateOwnerInformationRequest): Promise<runtime.ApiResponse<OwnerInformation>> {
        if (requestParameters.ownerInformation === null || requestParameters.ownerInformation === undefined) {
            throw new runtime.RequiredError('ownerInformation','Required parameter requestParameters.ownerInformation was null or undefined when calling updateOwnerInformation.');
        }

        if (requestParameters.surveyId === null || requestParameters.surveyId === undefined) {
            throw new runtime.RequiredError('surveyId','Required parameter requestParameters.surveyId was null or undefined when calling updateOwnerInformation.');
        }

        if (requestParameters.ownerId === null || requestParameters.ownerId === undefined) {
            throw new runtime.RequiredError('ownerId','Required parameter requestParameters.ownerId was null or undefined when calling updateOwnerInformation.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/surveys/{surveyId}/owners/{ownerId}`.replace(`{${"surveyId"}}`, encodeURIComponent(String(requestParameters.surveyId))).replace(`{${"ownerId"}}`, encodeURIComponent(String(requestParameters.ownerId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: OwnerInformationToJSON(requestParameters.ownerInformation),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => OwnerInformationFromJSON(jsonValue));
    }

    /**
     * Updates owner information object.
     * Update owner information object.
     */
    async updateOwnerInformation(requestParameters: UpdateOwnerInformationRequest): Promise<OwnerInformation> {
        const response = await this.updateOwnerInformationRaw(requestParameters);
        return await response.value();
    }

}
