/* tslint:disable */
/* eslint-disable */
/**
 * Rapurc API-spec
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    JoinRequestStatus,
    JoinRequestStatusFromJSON,
    JoinRequestStatusFromJSONTyped,
    JoinRequestStatusToJSON,
    Metadata,
    MetadataFromJSON,
    MetadataFromJSONTyped,
    MetadataToJSON,
} from './';

/**
 * Invitation for user to join a group
 * @export
 * @interface GroupJoinInvite
 */
export interface GroupJoinInvite {
    /**
     * 
     * @type {string}
     * @memberof GroupJoinInvite
     */
    readonly id?: string;
    /**
     * user email address
     * @type {string}
     * @memberof GroupJoinInvite
     */
    email: string;
    /**
     * group id
     * @type {string}
     * @memberof GroupJoinInvite
     */
    groupId: string;
    /**
     * 
     * @type {JoinRequestStatus}
     * @memberof GroupJoinInvite
     */
    status: JoinRequestStatus;
    /**
     * full name of the user who sent the invite, gets filled in during invite creation
     * @type {string}
     * @memberof GroupJoinInvite
     */
    readonly invitingUserName?: string;
    /**
     * 
     * @type {Metadata}
     * @memberof GroupJoinInvite
     */
    metadata?: Metadata;
}

export function GroupJoinInviteFromJSON(json: any): GroupJoinInvite {
    return GroupJoinInviteFromJSONTyped(json, false);
}

export function GroupJoinInviteFromJSONTyped(json: any, ignoreDiscriminator: boolean): GroupJoinInvite {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'email': json['email'],
        'groupId': json['groupId'],
        'status': JoinRequestStatusFromJSON(json['status']),
        'invitingUserName': !exists(json, 'invitingUserName') ? undefined : json['invitingUserName'],
        'metadata': !exists(json, 'metadata') ? undefined : MetadataFromJSON(json['metadata']),
    };
}

export function GroupJoinInviteToJSON(value?: GroupJoinInvite | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'email': value.email,
        'groupId': value.groupId,
        'status': JoinRequestStatusToJSON(value.status),
        'metadata': MetadataToJSON(value.metadata),
    };
}


