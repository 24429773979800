/* tslint:disable */
/* eslint-disable */
/**
 * Rapurc API-spec
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * Address of the building
 * @export
 * @interface Address
 */
export interface Address {
    /**
     * Street address
     * @type {string}
     * @memberof Address
     */
    streetAddress: string;
    /**
     * City
     * @type {string}
     * @memberof Address
     */
    city: string;
    /**
     * Post code
     * @type {string}
     * @memberof Address
     */
    postCode: string;
}

export function AddressFromJSON(json: any): Address {
    return AddressFromJSONTyped(json, false);
}

export function AddressFromJSONTyped(json: any, ignoreDiscriminator: boolean): Address {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'streetAddress': json['streetAddress'],
        'city': json['city'],
        'postCode': json['postCode'],
    };
}

export function AddressToJSON(value?: Address | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'streetAddress': value.streetAddress,
        'city': value.city,
        'postCode': value.postCode,
    };
}


