/* tslint:disable */
/* eslint-disable */
/**
 * Rapurc API-spec
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    HazardousMaterial,
    HazardousMaterialFromJSON,
    HazardousMaterialToJSON,
} from '../models';

export interface CreateHazardousMaterialRequest {
    hazardousMaterial: HazardousMaterial;
}

export interface DeleteHazardousMaterialRequest {
    hazardousMaterialId: string;
}

export interface FindHazardousMaterialRequest {
    hazardousMaterialId: string;
}

export interface UpdateHazardousMaterialRequest {
    hazardousMaterial: HazardousMaterial;
    hazardousMaterialId: string;
}

/**
 * 
 */
export class HazardousMaterialApi extends runtime.BaseAPI {

    /**
     * Creates a new hazardous material.
     * Create a new hazardous material.
     */
    async createHazardousMaterialRaw(requestParameters: CreateHazardousMaterialRequest): Promise<runtime.ApiResponse<HazardousMaterial>> {
        if (requestParameters.hazardousMaterial === null || requestParameters.hazardousMaterial === undefined) {
            throw new runtime.RequiredError('hazardousMaterial','Required parameter requestParameters.hazardousMaterial was null or undefined when calling createHazardousMaterial.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/hazardousMaterials`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: HazardousMaterialToJSON(requestParameters.hazardousMaterial),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => HazardousMaterialFromJSON(jsonValue));
    }

    /**
     * Creates a new hazardous material.
     * Create a new hazardous material.
     */
    async createHazardousMaterial(requestParameters: CreateHazardousMaterialRequest): Promise<HazardousMaterial> {
        const response = await this.createHazardousMaterialRaw(requestParameters);
        return await response.value();
    }

    /**
     * Deletes hazardous material.
     * Delete hazardous material.
     */
    async deleteHazardousMaterialRaw(requestParameters: DeleteHazardousMaterialRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.hazardousMaterialId === null || requestParameters.hazardousMaterialId === undefined) {
            throw new runtime.RequiredError('hazardousMaterialId','Required parameter requestParameters.hazardousMaterialId was null or undefined when calling deleteHazardousMaterial.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/hazardousMaterials/{hazardousMaterialId}`.replace(`{${"hazardousMaterialId"}}`, encodeURIComponent(String(requestParameters.hazardousMaterialId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Deletes hazardous material.
     * Delete hazardous material.
     */
    async deleteHazardousMaterial(requestParameters: DeleteHazardousMaterialRequest): Promise<void> {
        await this.deleteHazardousMaterialRaw(requestParameters);
    }

    /**
     * Finds hazardous material by id.
     * Find hazardous material.
     */
    async findHazardousMaterialRaw(requestParameters: FindHazardousMaterialRequest): Promise<runtime.ApiResponse<HazardousMaterial>> {
        if (requestParameters.hazardousMaterialId === null || requestParameters.hazardousMaterialId === undefined) {
            throw new runtime.RequiredError('hazardousMaterialId','Required parameter requestParameters.hazardousMaterialId was null or undefined when calling findHazardousMaterial.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/hazardousMaterials/{hazardousMaterialId}`.replace(`{${"hazardousMaterialId"}}`, encodeURIComponent(String(requestParameters.hazardousMaterialId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => HazardousMaterialFromJSON(jsonValue));
    }

    /**
     * Finds hazardous material by id.
     * Find hazardous material.
     */
    async findHazardousMaterial(requestParameters: FindHazardousMaterialRequest): Promise<HazardousMaterial> {
        const response = await this.findHazardousMaterialRaw(requestParameters);
        return await response.value();
    }

    /**
     * Lists hazardous material. Results are in alphabetical order
     * List hazardous materials
     */
    async listHazardousMaterialsRaw(): Promise<runtime.ApiResponse<Array<HazardousMaterial>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/hazardousMaterials`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(HazardousMaterialFromJSON));
    }

    /**
     * Lists hazardous material. Results are in alphabetical order
     * List hazardous materials
     */
    async listHazardousMaterials(): Promise<Array<HazardousMaterial>> {
        const response = await this.listHazardousMaterialsRaw();
        return await response.value();
    }

    /**
     * Updates hazardous material object.
     * Update hazardous material object.
     */
    async updateHazardousMaterialRaw(requestParameters: UpdateHazardousMaterialRequest): Promise<runtime.ApiResponse<HazardousMaterial>> {
        if (requestParameters.hazardousMaterial === null || requestParameters.hazardousMaterial === undefined) {
            throw new runtime.RequiredError('hazardousMaterial','Required parameter requestParameters.hazardousMaterial was null or undefined when calling updateHazardousMaterial.');
        }

        if (requestParameters.hazardousMaterialId === null || requestParameters.hazardousMaterialId === undefined) {
            throw new runtime.RequiredError('hazardousMaterialId','Required parameter requestParameters.hazardousMaterialId was null or undefined when calling updateHazardousMaterial.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/hazardousMaterials/{hazardousMaterialId}`.replace(`{${"hazardousMaterialId"}}`, encodeURIComponent(String(requestParameters.hazardousMaterialId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: HazardousMaterialToJSON(requestParameters.hazardousMaterial),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => HazardousMaterialFromJSON(jsonValue));
    }

    /**
     * Updates hazardous material object.
     * Update hazardous material object.
     */
    async updateHazardousMaterial(requestParameters: UpdateHazardousMaterialRequest): Promise<HazardousMaterial> {
        const response = await this.updateHazardousMaterialRaw(requestParameters);
        return await response.value();
    }

}
