/* tslint:disable */
/* eslint-disable */
/**
 * Rapurc API-spec
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Status of group join
 * @export
 * @enum {string}
 */
export enum JoinRequestStatus {
    Pending = 'PENDING',
    Rejected = 'REJECTED',
    Accepted = 'ACCEPTED'
}

export function JoinRequestStatusFromJSON(json: any): JoinRequestStatus {
    return JoinRequestStatusFromJSONTyped(json, false);
}

export function JoinRequestStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): JoinRequestStatus {
    return json as JoinRequestStatus;
}

export function JoinRequestStatusToJSON(value?: JoinRequestStatus | null): any {
    return value as any;
}

