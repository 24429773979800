/* tslint:disable */
/* eslint-disable */
/**
 * Rapurc API-spec
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    EmailTemplate,
    EmailTemplateFromJSON,
    EmailTemplateToJSON,
} from '../models';

export interface SendSurveyEmailRequest {
    emailTemplate: EmailTemplate;
    surveyId: string;
}

/**
 * 
 */
export class EmailsApi extends runtime.BaseAPI {

    /**
     * Sends email
     * Sends email concerning a survey
     */
    async sendSurveyEmailRaw(requestParameters: SendSurveyEmailRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.emailTemplate === null || requestParameters.emailTemplate === undefined) {
            throw new runtime.RequiredError('emailTemplate','Required parameter requestParameters.emailTemplate was null or undefined when calling sendSurveyEmail.');
        }

        if (requestParameters.surveyId === null || requestParameters.surveyId === undefined) {
            throw new runtime.RequiredError('surveyId','Required parameter requestParameters.surveyId was null or undefined when calling sendSurveyEmail.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/surveys/{surveyId}/sendEmail`.replace(`{${"surveyId"}}`, encodeURIComponent(String(requestParameters.surveyId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: EmailTemplateToJSON(requestParameters.emailTemplate),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Sends email
     * Sends email concerning a survey
     */
    async sendSurveyEmail(requestParameters: SendSurveyEmailRequest): Promise<void> {
        await this.sendSurveyEmailRaw(requestParameters);
    }

}
