/* tslint:disable */
/* eslint-disable */
/**
 * Rapurc API-spec
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    GroupJoinInvite,
    GroupJoinInviteFromJSON,
    GroupJoinInviteToJSON,
} from '../models';

export interface CreateGroupJoinInviteRequest {
    groupJoinInvite: GroupJoinInvite;
    groupId: string;
}

export interface DeleteGroupJoinInviteRequest {
    groupId: string;
    inviteId: string;
}

export interface FindGroupJoinInviteRequest {
    groupId: string;
    inviteId: string;
}

export interface ListGroupJoinInvitesRequest {
    groupId: string;
}

export interface SendGroupJoinInviteEmailRequest {
    groupId: string;
    inviteId: string;
}

export interface UpdateGroupJoinInviteRequest {
    groupJoinInvite: GroupJoinInvite;
    groupId: string;
    inviteId: string;
}

/**
 * 
 */
export class GroupJoinInvitesApi extends runtime.BaseAPI {

    /**
     * Creates a group join invite. Can be created by group admin only
     * Create a new group join invite.
     */
    async createGroupJoinInviteRaw(requestParameters: CreateGroupJoinInviteRequest): Promise<runtime.ApiResponse<GroupJoinInvite>> {
        if (requestParameters.groupJoinInvite === null || requestParameters.groupJoinInvite === undefined) {
            throw new runtime.RequiredError('groupJoinInvite','Required parameter requestParameters.groupJoinInvite was null or undefined when calling createGroupJoinInvite.');
        }

        if (requestParameters.groupId === null || requestParameters.groupId === undefined) {
            throw new runtime.RequiredError('groupId','Required parameter requestParameters.groupId was null or undefined when calling createGroupJoinInvite.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/groups/{groupId}/invites`.replace(`{${"groupId"}}`, encodeURIComponent(String(requestParameters.groupId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: GroupJoinInviteToJSON(requestParameters.groupJoinInvite),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GroupJoinInviteFromJSON(jsonValue));
    }

    /**
     * Creates a group join invite. Can be created by group admin only
     * Create a new group join invite.
     */
    async createGroupJoinInvite(requestParameters: CreateGroupJoinInviteRequest): Promise<GroupJoinInvite> {
        const response = await this.createGroupJoinInviteRaw(requestParameters);
        return await response.value();
    }

    /**
     * Deletes user group join invite. Can be deleted by group admin only
     * Delete user group join invite.
     */
    async deleteGroupJoinInviteRaw(requestParameters: DeleteGroupJoinInviteRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.groupId === null || requestParameters.groupId === undefined) {
            throw new runtime.RequiredError('groupId','Required parameter requestParameters.groupId was null or undefined when calling deleteGroupJoinInvite.');
        }

        if (requestParameters.inviteId === null || requestParameters.inviteId === undefined) {
            throw new runtime.RequiredError('inviteId','Required parameter requestParameters.inviteId was null or undefined when calling deleteGroupJoinInvite.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/groups/{groupId}/invites/{inviteId}`.replace(`{${"groupId"}}`, encodeURIComponent(String(requestParameters.groupId))).replace(`{${"inviteId"}}`, encodeURIComponent(String(requestParameters.inviteId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Deletes user group join invite. Can be deleted by group admin only
     * Delete user group join invite.
     */
    async deleteGroupJoinInvite(requestParameters: DeleteGroupJoinInviteRequest): Promise<void> {
        await this.deleteGroupJoinInviteRaw(requestParameters);
    }

    /**
     * Finds group join invite by id. Can be viewed by group admin only or invited user
     * Find group join invite by id
     */
    async findGroupJoinInviteRaw(requestParameters: FindGroupJoinInviteRequest): Promise<runtime.ApiResponse<GroupJoinInvite>> {
        if (requestParameters.groupId === null || requestParameters.groupId === undefined) {
            throw new runtime.RequiredError('groupId','Required parameter requestParameters.groupId was null or undefined when calling findGroupJoinInvite.');
        }

        if (requestParameters.inviteId === null || requestParameters.inviteId === undefined) {
            throw new runtime.RequiredError('inviteId','Required parameter requestParameters.inviteId was null or undefined when calling findGroupJoinInvite.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/groups/{groupId}/invites/{inviteId}`.replace(`{${"groupId"}}`, encodeURIComponent(String(requestParameters.groupId))).replace(`{${"inviteId"}}`, encodeURIComponent(String(requestParameters.inviteId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GroupJoinInviteFromJSON(jsonValue));
    }

    /**
     * Finds group join invite by id. Can be viewed by group admin only or invited user
     * Find group join invite by id
     */
    async findGroupJoinInvite(requestParameters: FindGroupJoinInviteRequest): Promise<GroupJoinInvite> {
        const response = await this.findGroupJoinInviteRaw(requestParameters);
        return await response.value();
    }

    /**
     * Lists all group join invites for the group. Group admin can list join invites.
     * Lists group join invites
     */
    async listGroupJoinInvitesRaw(requestParameters: ListGroupJoinInvitesRequest): Promise<runtime.ApiResponse<Array<GroupJoinInvite>>> {
        if (requestParameters.groupId === null || requestParameters.groupId === undefined) {
            throw new runtime.RequiredError('groupId','Required parameter requestParameters.groupId was null or undefined when calling listGroupJoinInvites.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/groups/{groupId}/invites`.replace(`{${"groupId"}}`, encodeURIComponent(String(requestParameters.groupId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GroupJoinInviteFromJSON));
    }

    /**
     * Lists all group join invites for the group. Group admin can list join invites.
     * Lists group join invites
     */
    async listGroupJoinInvites(requestParameters: ListGroupJoinInvitesRequest): Promise<Array<GroupJoinInvite>> {
        const response = await this.listGroupJoinInvitesRaw(requestParameters);
        return await response.value();
    }

    /**
     * Lists all group join invites for the user. Any user can list join invites.
     * Lists user group join invites
     */
    async listUserGroupJoinInvitesRaw(): Promise<runtime.ApiResponse<Array<GroupJoinInvite>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/groups/invites`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GroupJoinInviteFromJSON));
    }

    /**
     * Lists all group join invites for the user. Any user can list join invites.
     * Lists user group join invites
     */
    async listUserGroupJoinInvites(): Promise<Array<GroupJoinInvite>> {
        const response = await this.listUserGroupJoinInvitesRaw();
        return await response.value();
    }

    /**
     * Sends group join invite email. Can be sent by group admin only
     * Send group join invite email
     */
    async sendGroupJoinInviteEmailRaw(requestParameters: SendGroupJoinInviteEmailRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.groupId === null || requestParameters.groupId === undefined) {
            throw new runtime.RequiredError('groupId','Required parameter requestParameters.groupId was null or undefined when calling sendGroupJoinInviteEmail.');
        }

        if (requestParameters.inviteId === null || requestParameters.inviteId === undefined) {
            throw new runtime.RequiredError('inviteId','Required parameter requestParameters.inviteId was null or undefined when calling sendGroupJoinInviteEmail.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/groups/{groupId}/invites/{inviteId}/sendEmail`.replace(`{${"groupId"}}`, encodeURIComponent(String(requestParameters.groupId))).replace(`{${"inviteId"}}`, encodeURIComponent(String(requestParameters.inviteId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Sends group join invite email. Can be sent by group admin only
     * Send group join invite email
     */
    async sendGroupJoinInviteEmail(requestParameters: SendGroupJoinInviteEmailRequest): Promise<void> {
        await this.sendGroupJoinInviteEmailRaw(requestParameters);
    }

    /**
     * Updates group join invite. Can be updated by invited user
     * Updates group join invite
     */
    async updateGroupJoinInviteRaw(requestParameters: UpdateGroupJoinInviteRequest): Promise<runtime.ApiResponse<GroupJoinInvite>> {
        if (requestParameters.groupJoinInvite === null || requestParameters.groupJoinInvite === undefined) {
            throw new runtime.RequiredError('groupJoinInvite','Required parameter requestParameters.groupJoinInvite was null or undefined when calling updateGroupJoinInvite.');
        }

        if (requestParameters.groupId === null || requestParameters.groupId === undefined) {
            throw new runtime.RequiredError('groupId','Required parameter requestParameters.groupId was null or undefined when calling updateGroupJoinInvite.');
        }

        if (requestParameters.inviteId === null || requestParameters.inviteId === undefined) {
            throw new runtime.RequiredError('inviteId','Required parameter requestParameters.inviteId was null or undefined when calling updateGroupJoinInvite.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/groups/{groupId}/invites/{inviteId}`.replace(`{${"groupId"}}`, encodeURIComponent(String(requestParameters.groupId))).replace(`{${"inviteId"}}`, encodeURIComponent(String(requestParameters.inviteId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: GroupJoinInviteToJSON(requestParameters.groupJoinInvite),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GroupJoinInviteFromJSON(jsonValue));
    }

    /**
     * Updates group join invite. Can be updated by invited user
     * Updates group join invite
     */
    async updateGroupJoinInvite(requestParameters: UpdateGroupJoinInviteRequest): Promise<GroupJoinInvite> {
        const response = await this.updateGroupJoinInviteRaw(requestParameters);
        return await response.value();
    }

}
