/* tslint:disable */
/* eslint-disable */
/**
 * Rapurc API-spec
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    Building,
    BuildingFromJSON,
    BuildingToJSON,
} from '../models';

export interface CreateBuildingRequest {
    building: Building;
    surveyId: string;
}

export interface DeleteBuildingRequest {
    surveyId: string;
    buildingId: string;
}

export interface FindBuildingRequest {
    surveyId: string;
    buildingId: string;
}

export interface ListBuildingsRequest {
    surveyId: string;
}

export interface UpdateBuildingRequest {
    building: Building;
    surveyId: string;
    buildingId: string;
}

/**
 * 
 */
export class BuildingsApi extends runtime.BaseAPI {

    /**
     * Creates a new building object.
     * Create a new building object.
     */
    async createBuildingRaw(requestParameters: CreateBuildingRequest): Promise<runtime.ApiResponse<Building>> {
        if (requestParameters.building === null || requestParameters.building === undefined) {
            throw new runtime.RequiredError('building','Required parameter requestParameters.building was null or undefined when calling createBuilding.');
        }

        if (requestParameters.surveyId === null || requestParameters.surveyId === undefined) {
            throw new runtime.RequiredError('surveyId','Required parameter requestParameters.surveyId was null or undefined when calling createBuilding.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/surveys/{surveyId}/buildings`.replace(`{${"surveyId"}}`, encodeURIComponent(String(requestParameters.surveyId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: BuildingToJSON(requestParameters.building),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => BuildingFromJSON(jsonValue));
    }

    /**
     * Creates a new building object.
     * Create a new building object.
     */
    async createBuilding(requestParameters: CreateBuildingRequest): Promise<Building> {
        const response = await this.createBuildingRaw(requestParameters);
        return await response.value();
    }

    /**
     * Deletes building.
     * Delete building.
     */
    async deleteBuildingRaw(requestParameters: DeleteBuildingRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.surveyId === null || requestParameters.surveyId === undefined) {
            throw new runtime.RequiredError('surveyId','Required parameter requestParameters.surveyId was null or undefined when calling deleteBuilding.');
        }

        if (requestParameters.buildingId === null || requestParameters.buildingId === undefined) {
            throw new runtime.RequiredError('buildingId','Required parameter requestParameters.buildingId was null or undefined when calling deleteBuilding.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/surveys/{surveyId}/buildings/{buildingId}`.replace(`{${"surveyId"}}`, encodeURIComponent(String(requestParameters.surveyId))).replace(`{${"buildingId"}}`, encodeURIComponent(String(requestParameters.buildingId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Deletes building.
     * Delete building.
     */
    async deleteBuilding(requestParameters: DeleteBuildingRequest): Promise<void> {
        await this.deleteBuildingRaw(requestParameters);
    }

    /**
     * Finds building by id.
     * Find building.
     */
    async findBuildingRaw(requestParameters: FindBuildingRequest): Promise<runtime.ApiResponse<Building>> {
        if (requestParameters.surveyId === null || requestParameters.surveyId === undefined) {
            throw new runtime.RequiredError('surveyId','Required parameter requestParameters.surveyId was null or undefined when calling findBuilding.');
        }

        if (requestParameters.buildingId === null || requestParameters.buildingId === undefined) {
            throw new runtime.RequiredError('buildingId','Required parameter requestParameters.buildingId was null or undefined when calling findBuilding.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/surveys/{surveyId}/buildings/{buildingId}`.replace(`{${"surveyId"}}`, encodeURIComponent(String(requestParameters.surveyId))).replace(`{${"buildingId"}}`, encodeURIComponent(String(requestParameters.buildingId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => BuildingFromJSON(jsonValue));
    }

    /**
     * Finds building by id.
     * Find building.
     */
    async findBuilding(requestParameters: FindBuildingRequest): Promise<Building> {
        const response = await this.findBuildingRaw(requestParameters);
        return await response.value();
    }

    /**
     * Lists buildins
     * List buildings
     */
    async listBuildingsRaw(requestParameters: ListBuildingsRequest): Promise<runtime.ApiResponse<Array<Building>>> {
        if (requestParameters.surveyId === null || requestParameters.surveyId === undefined) {
            throw new runtime.RequiredError('surveyId','Required parameter requestParameters.surveyId was null or undefined when calling listBuildings.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/surveys/{surveyId}/buildings`.replace(`{${"surveyId"}}`, encodeURIComponent(String(requestParameters.surveyId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(BuildingFromJSON));
    }

    /**
     * Lists buildins
     * List buildings
     */
    async listBuildings(requestParameters: ListBuildingsRequest): Promise<Array<Building>> {
        const response = await this.listBuildingsRaw(requestParameters);
        return await response.value();
    }

    /**
     * Updates building object.
     * Update building object.
     */
    async updateBuildingRaw(requestParameters: UpdateBuildingRequest): Promise<runtime.ApiResponse<Building>> {
        if (requestParameters.building === null || requestParameters.building === undefined) {
            throw new runtime.RequiredError('building','Required parameter requestParameters.building was null or undefined when calling updateBuilding.');
        }

        if (requestParameters.surveyId === null || requestParameters.surveyId === undefined) {
            throw new runtime.RequiredError('surveyId','Required parameter requestParameters.surveyId was null or undefined when calling updateBuilding.');
        }

        if (requestParameters.buildingId === null || requestParameters.buildingId === undefined) {
            throw new runtime.RequiredError('buildingId','Required parameter requestParameters.buildingId was null or undefined when calling updateBuilding.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/surveys/{surveyId}/buildings/{buildingId}`.replace(`{${"surveyId"}}`, encodeURIComponent(String(requestParameters.surveyId))).replace(`{${"buildingId"}}`, encodeURIComponent(String(requestParameters.buildingId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: BuildingToJSON(requestParameters.building),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => BuildingFromJSON(jsonValue));
    }

    /**
     * Updates building object.
     * Update building object.
     */
    async updateBuilding(requestParameters: UpdateBuildingRequest): Promise<Building> {
        const response = await this.updateBuildingRaw(requestParameters);
        return await response.value();
    }

}
