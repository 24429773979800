/* tslint:disable */
/* eslint-disable */
/**
 * Rapurc API-spec
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * Metadata object
 * @export
 * @interface Metadata
 */
export interface Metadata {
    /**
     * ID of creator
     * @type {string}
     * @memberof Metadata
     */
    readonly creatorId?: string;
    /**
     * Created date
     * @type {Date}
     * @memberof Metadata
     */
    readonly createdAt?: Date;
    /**
     * Id of last modifier
     * @type {string}
     * @memberof Metadata
     */
    readonly lastModifierId?: string;
    /**
     * Date modified
     * @type {Date}
     * @memberof Metadata
     */
    readonly modifiedAt?: Date;
}

export function MetadataFromJSON(json: any): Metadata {
    return MetadataFromJSONTyped(json, false);
}

export function MetadataFromJSONTyped(json: any, ignoreDiscriminator: boolean): Metadata {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'creatorId': !exists(json, 'creatorId') ? undefined : json['creatorId'],
        'createdAt': !exists(json, 'createdAt') ? undefined : (new Date(json['createdAt'])),
        'lastModifierId': !exists(json, 'lastModifierId') ? undefined : json['lastModifierId'],
        'modifiedAt': !exists(json, 'modifiedAt') ? undefined : (new Date(json['modifiedAt'])),
    };
}

export function MetadataToJSON(value?: Metadata | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
    };
}


