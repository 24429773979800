/* tslint:disable */
/* eslint-disable */
/**
 * Rapurc API-spec
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ReusableMaterial,
    ReusableMaterialFromJSON,
    ReusableMaterialToJSON,
} from '../models';

export interface CreateReusableMaterialRequest {
    reusableMaterial: ReusableMaterial;
}

export interface DeleteReusableMaterialRequest {
    reusableMaterialId: string;
}

export interface FindReusableMaterialRequest {
    reusableMaterialId: string;
}

export interface UpdateReusableMaterialRequest {
    reusableMaterial: ReusableMaterial;
    reusableMaterialId: string;
}

/**
 * 
 */
export class ReusableMaterialApi extends runtime.BaseAPI {

    /**
     * Creates a new reusable material.
     * Create a new reusable material.
     */
    async createReusableMaterialRaw(requestParameters: CreateReusableMaterialRequest): Promise<runtime.ApiResponse<ReusableMaterial>> {
        if (requestParameters.reusableMaterial === null || requestParameters.reusableMaterial === undefined) {
            throw new runtime.RequiredError('reusableMaterial','Required parameter requestParameters.reusableMaterial was null or undefined when calling createReusableMaterial.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/reusableMaterials`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ReusableMaterialToJSON(requestParameters.reusableMaterial),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ReusableMaterialFromJSON(jsonValue));
    }

    /**
     * Creates a new reusable material.
     * Create a new reusable material.
     */
    async createReusableMaterial(requestParameters: CreateReusableMaterialRequest): Promise<ReusableMaterial> {
        const response = await this.createReusableMaterialRaw(requestParameters);
        return await response.value();
    }

    /**
     * Deletes reusable material.
     * Delete reusable material.
     */
    async deleteReusableMaterialRaw(requestParameters: DeleteReusableMaterialRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.reusableMaterialId === null || requestParameters.reusableMaterialId === undefined) {
            throw new runtime.RequiredError('reusableMaterialId','Required parameter requestParameters.reusableMaterialId was null or undefined when calling deleteReusableMaterial.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/reusableMaterials/{reusableMaterialId}`.replace(`{${"reusableMaterialId"}}`, encodeURIComponent(String(requestParameters.reusableMaterialId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Deletes reusable material.
     * Delete reusable material.
     */
    async deleteReusableMaterial(requestParameters: DeleteReusableMaterialRequest): Promise<void> {
        await this.deleteReusableMaterialRaw(requestParameters);
    }

    /**
     * Finds reusable material by id.
     * Find reusable material.
     */
    async findReusableMaterialRaw(requestParameters: FindReusableMaterialRequest): Promise<runtime.ApiResponse<ReusableMaterial>> {
        if (requestParameters.reusableMaterialId === null || requestParameters.reusableMaterialId === undefined) {
            throw new runtime.RequiredError('reusableMaterialId','Required parameter requestParameters.reusableMaterialId was null or undefined when calling findReusableMaterial.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/reusableMaterials/{reusableMaterialId}`.replace(`{${"reusableMaterialId"}}`, encodeURIComponent(String(requestParameters.reusableMaterialId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ReusableMaterialFromJSON(jsonValue));
    }

    /**
     * Finds reusable material by id.
     * Find reusable material.
     */
    async findReusableMaterial(requestParameters: FindReusableMaterialRequest): Promise<ReusableMaterial> {
        const response = await this.findReusableMaterialRaw(requestParameters);
        return await response.value();
    }

    /**
     * Lists reusable material. Results are in alphabetical order
     * List reusable materials
     */
    async listReusableMaterialsRaw(): Promise<runtime.ApiResponse<Array<ReusableMaterial>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/reusableMaterials`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ReusableMaterialFromJSON));
    }

    /**
     * Lists reusable material. Results are in alphabetical order
     * List reusable materials
     */
    async listReusableMaterials(): Promise<Array<ReusableMaterial>> {
        const response = await this.listReusableMaterialsRaw();
        return await response.value();
    }

    /**
     * Updates reusable material object.
     * Update reusable material object.
     */
    async updateReusableMaterialRaw(requestParameters: UpdateReusableMaterialRequest): Promise<runtime.ApiResponse<ReusableMaterial>> {
        if (requestParameters.reusableMaterial === null || requestParameters.reusableMaterial === undefined) {
            throw new runtime.RequiredError('reusableMaterial','Required parameter requestParameters.reusableMaterial was null or undefined when calling updateReusableMaterial.');
        }

        if (requestParameters.reusableMaterialId === null || requestParameters.reusableMaterialId === undefined) {
            throw new runtime.RequiredError('reusableMaterialId','Required parameter requestParameters.reusableMaterialId was null or undefined when calling updateReusableMaterial.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/reusableMaterials/{reusableMaterialId}`.replace(`{${"reusableMaterialId"}}`, encodeURIComponent(String(requestParameters.reusableMaterialId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ReusableMaterialToJSON(requestParameters.reusableMaterial),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ReusableMaterialFromJSON(jsonValue));
    }

    /**
     * Updates reusable material object.
     * Update reusable material object.
     */
    async updateReusableMaterial(requestParameters: UpdateReusableMaterialRequest): Promise<ReusableMaterial> {
        const response = await this.updateReusableMaterialRaw(requestParameters);
        return await response.value();
    }

}
