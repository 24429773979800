/* tslint:disable */
/* eslint-disable */
/**
 * Rapurc API-spec
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Unit enum
 * @export
 * @enum {string}
 */
export enum Unit {
    Kg = 'KG',
    M2 = 'M2',
    M3 = 'M3',
    Pcs = 'PCS',
    Rm = 'RM',
    Tn = 'TN'
}

export function UnitFromJSON(json: any): Unit {
    return UnitFromJSONTyped(json, false);
}

export function UnitFromJSONTyped(json: any, ignoreDiscriminator: boolean): Unit {
    return json as Unit;
}

export function UnitToJSON(value?: Unit | null): any {
    return value as any;
}

