/* tslint:disable */
/* eslint-disable */
/**
 * Rapurc API-spec
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    LocalizedValue,
    LocalizedValueFromJSON,
    LocalizedValueFromJSONTyped,
    LocalizedValueToJSON,
    Metadata,
    MetadataFromJSON,
    MetadataFromJSONTyped,
    MetadataToJSON,
} from './';

/**
 * Building type object
 * @export
 * @interface BuildingType
 */
export interface BuildingType {
    /**
     * 
     * @type {string}
     * @memberof BuildingType
     */
    readonly id?: string;
    /**
     * Localized building type names
     * @type {Array<LocalizedValue>}
     * @memberof BuildingType
     */
    localizedNames: Array<LocalizedValue>;
    /**
     * Building type code
     * @type {string}
     * @memberof BuildingType
     */
    code: string;
    /**
     * 
     * @type {Metadata}
     * @memberof BuildingType
     */
    metadata: Metadata;
}

export function BuildingTypeFromJSON(json: any): BuildingType {
    return BuildingTypeFromJSONTyped(json, false);
}

export function BuildingTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): BuildingType {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'localizedNames': ((json['localizedNames'] as Array<any>).map(LocalizedValueFromJSON)),
        'code': json['code'],
        'metadata': MetadataFromJSON(json['metadata']),
    };
}

export function BuildingTypeToJSON(value?: BuildingType | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'localizedNames': ((value.localizedNames as Array<any>).map(LocalizedValueToJSON)),
        'code': value.code,
        'metadata': MetadataToJSON(value.metadata),
    };
}


